import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebase';
import EvaluationSuccessModal from './EvaluationSuccessModal'; // Import the SuccessModal component

const EvaluationModal = ({ isOpen, onClose, classData }) => {
  const [recSTEMHSS, setRecSTEMHSS] = useState(0);
  const [recProjectTest, setRecProjectTest] = useState(0);
  const [recInPersonOnline, setRecInPersonOnline] = useState(0);
  const [recStructuredFlexible, setRecStructuredFlexible] = useState(0);
  const [recSmallLargeClass, setRecSmallLargeClass] = useState(0);
  const [recAppliedTheoretical, setRecAppliedTheoretical] = useState(0);
  const [recCollaborativeIndividual, setRecCollaborativeIndividual] = useState(0);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const questions = [
    { question: 'Which of these topics did the course mostly resemble: science and technology (STEM) or history and literature (Humanities and Social Sciences)?', Option1: 'STEM', Option2: 'Humanities and Social Sciences', value: recSTEMHSS, setter: setRecSTEMHSS },
    { question: 'Which approach did the course mostly use: completing projects and hands-on activities or preparing for and taking exams?', Option1: 'Project-Based', Option2: 'Test-Based', value: recProjectTest, setter: setRecProjectTest },
    { question: 'Which learning method did the course primarily follow: attending classes in person or taking courses online?', Option1: 'In-Person', Option2: 'Online', value: recInPersonOnline, setter: setRecInPersonOnline },
    { question: 'Which environment did the course mostly reflect: a structured schedule with set deadlines or a flexible, self-paced learning schedule?', Option1: 'Structured Environment', Option2: 'Flexible, self-paced schedule', value: recStructuredFlexible, setter: setRecStructuredFlexible },
    { question: 'Which class size did the course mostly involve: large lectures or small classes?', Option1: 'Large Lectures', Option2: 'Small Classes', value: recSmallLargeClass, setter: setRecSmallLargeClass },
    { question: 'Which focus did the course primarily have: learning through real-world applications or studying theoretical concepts?', Option1: 'Real World Applications', Option2: 'Theoretical Focus', value: recAppliedTheoretical, setter: setRecAppliedTheoretical },
    { question: 'Which work style did the course mostly emphasize: collaborating with others on group projects or working independently on your own?', Option1: 'Collaborative Environment', Option2: 'Individual Work', value: recCollaborativeIndividual, setter: setRecCollaborativeIndividual }
  ];

  useEffect(() => {
    const fetchEvaluationData = async () => {
      setLoading(true);
      setRecSTEMHSS(0);
      setRecProjectTest(0);
      setRecInPersonOnline(0);
      setRecStructuredFlexible(0);
      setRecSmallLargeClass(0);
      setRecAppliedTheoretical(0);
      setRecCollaborativeIndividual(0);

      const user = auth.currentUser;
      if (user) {
        const userDoc = await db.collection('users').doc(user.uid).get();
        const evaluations = userDoc.data()?.evaluations || {};
        const previousEvaluation = evaluations[classData.courseInfo_courseNumber];

        if (previousEvaluation) {
          setRecSTEMHSS(previousEvaluation.recSTEMHSS || 0);
          setRecProjectTest(previousEvaluation.recProjectTest || 0);
          setRecInPersonOnline(previousEvaluation.recInPersonOnline || 0);
          setRecStructuredFlexible(previousEvaluation.recStructuredFlexible || 0);
          setRecSmallLargeClass(previousEvaluation.recSmallLargeClass || 0);
          setRecAppliedTheoretical(previousEvaluation.recAppliedTheoretical || 0);
          setRecCollaborativeIndividual(previousEvaluation.recCollaborativeIndividual || 0);
        }
      }

      setLoading(false);
    };

    if (isOpen) {
      fetchEvaluationData().then(() => setShowModal(true));
    } else {
      setShowModal(false);
    }
  }, [isOpen, classData]);

  const saveEvaluation = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const evaluationData = {
          className: classData.courseInfo_courseName,
          recSTEMHSS,
          recProjectTest,
          recInPersonOnline,
          recStructuredFlexible,
          recSmallLargeClass,
          recAppliedTheoretical,
          recCollaborativeIndividual,
        };
        console.log('Evaluation Data:', evaluationData);
        await db.collection('users').doc(user.uid).set({
          evaluations: {
            [classData.courseInfo_courseNumber]: evaluationData
          }
        }, { merge: true });
        console.log('Evaluation saved successfully!');
        setShowSuccessModal(true);
      } else {
        console.log('User not authenticated');
        alert('You must be logged in to save evaluations.');
      }
    } catch (error) {
      console.error('Error saving evaluation:', error);
      alert('An error occurred while saving the evaluation.');
    }
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    onClose();
  };

  // Only render modal when showModal is true and not loading
  if (!showModal || loading) return null;

  return (
    <>
      {showModal && !showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <div className="bg-white p-4 rounded-lg shadow-lg w-10/12 max-w-3xl max-h-[90vh] mx-auto flex flex-col">
            <div className="flex justify-between items-center mb-3">
            <h2 className="text-2xl">
              {classData ? (
                <>
                  <span className="font-bold">{classData.courseInfo_courseNumber}</span> ({classData.courseInfo_courseName}) Evaluation
                </>
              ) : null}
            </h2>
              <button
                onClick={onClose}
                className="text-gray-500 text-2xl px-2 rounded hover:text-gray-800 hover:bg-gray-100"
              >
                &times;
              </button>
            </div>
            <div className="flex-1 overflow-y-auto">
              <p className="text-md text-gray-600 mt-2">
                Please fill out this quick survey about the course you previously took to help improve recommendations for future students.
              </p>
              <div className="mb-6">
                <div className="border-t border-gray-300 mt-4 mb-2"></div>
                {questions.map(({ question, Option1, Option2, value, setter }, index) => (
                <div key={index} className="flex flex-col items-center mt-4">
                  <div className="text-left w-full mb-4">
                    <span className="block text-md font-semibold">{question}</span>
                  </div>
                  <div className="flex justify-between w-full mb-2 text-sm font-medium text-gray-700">
                    <span>{Option1}</span>
                    <span>{Option2}</span>
                  </div>
                  <input
                    type="range"
                    min="-5"
                    max="5"
                    step="1"
                    value={value}
                    onChange={(e) => setter(parseInt(e.target.value))}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                    style={{
                      background: `linear-gradient(to right, #3b82f6 0%, #3b82f6 ${(value + 5) * 10}%, #e5e7eb ${(value + 5) * 10}%, #e5e7eb 100%)`,
                    }}
                  />
                  {index < questions.length - 1 && <hr className="mt-8 border-gray-500 w-full" />}
                </div>
              ))}
              </div>
              <div className="flex justify-end space-x-4 mt-4 justify-center">
                <button
                  onClick={onClose}
                  className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  Cancel
                </button>
                <button
                  onClick={saveEvaluation}
                  className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showSuccessModal && (
        <EvaluationSuccessModal isOpen={showSuccessModal} onClose={handleCloseSuccessModal} />
      )}
    </>
  );
};

export default EvaluationModal;
