import React, { useEffect, useState } from 'react';

const PopularPrograms = ({ course }) => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    if (course) {
      setPrograms(course.popularPrograms);
    }
  }, [course]);

  const maxCount = programs.length > 0 ? Math.max(...programs.map(program => program.count)) : 1;

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-3 md:p-6">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h5 className="text-sm font-medium text-gray-500 dark:text-gray-400">POPULAR PROGRAMS</h5>
        </div>
      </div>
      <div>
        {programs.map((program, index) => (
          <div key={index} className="flex justify-between items-center mb-2">
            <div className="flex-1">
              <span className="text-sm font-medium text-gray-900 dark:text-white">{program.name}</span>
              <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full mt-1">
                <div
                  className={`h-full rounded-full ${program.count === maxCount ? 'bg-blue-600' : 'bg-gray-400'}`}
                  style={{ width: `${(program.count / maxCount) * 100}%` }}
                ></div>
              </div>
            </div>
            <div className="ml-2 text-sm font-medium text-gray-900 dark:text-white">{program.count}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularPrograms;
