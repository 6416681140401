import React, { useState, useEffect, useMemo } from 'react';
import CustomSidebar from '../Sidebar/Sidebar';
import { FaDownload } from 'react-icons/fa'; // Download icon
import { Button, TextInput, Spinner, Modal, Label, Select, Checkbox } from 'flowbite-react';
import { getFirestore, collection, getDocs, addDoc, getDoc, doc, deleteDoc } from 'firebase/firestore';
import { saveAs } from 'file-saver'; // Ensure 'file-saver' is installed
import MultiSelect from './MultiSelect'; // Ensure the path is correct
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import React Toastify CSS
import { 
  BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer 
} from 'recharts';
import { useTable, useSortBy, usePagination, useFilters } from 'react-table';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Utility function to format Firestore Timestamps
const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleString(); // Customize the format as needed
  }
  return 'N/A';
};

// Column Filter Component for React Table
const ColumnFilter = ({ column: { filterValue, setFilter } }) => (
  <input
    value={filterValue || ''}
    onChange={(e) => setFilter(e.target.value || undefined)}
    placeholder={`Search...`}
    className="mt-1 p-1 border rounded w-full"
  />
);

// ReportTable Component using React Table
const ReportTable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of rows, use page for pagination
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <>
      <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  key={column.id}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                  <div>
                    {column.canFilter ? column.render('Filter') : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
          {page.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-700"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination mt-4 flex justify-between items-center">
        <div>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} size="xs" color="light" className="mr-1">
            {'<<'}
          </Button>
          <Button onClick={() => previousPage()} disabled={!canPreviousPage} size="xs" color="light" className="mr-1">
            {'<'}
          </Button>
          <Button onClick={() => nextPage()} disabled={!canNextPage} size="xs" color="light" className="mr-1">
            {'>'}
          </Button>
          <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} size="xs" color="light">
            {'>>'}
          </Button>
        </div>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <Select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
          className="border p-1 rounded"
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
};

const Reports = () => {
  const [reportModel, setReportModel] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [subFieldsMap, setSubFieldsMap] = useState({});
  const [fields, setFields] = useState([]);
  const [availableSubFieldsMap, setAvailableSubFieldsMap] = useState({});
  const [reportData, setReportData] = useState([]);
  const [reportColumns, setReportColumns] = useState([]);
  const [loadingFields, setLoadingFields] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [reportName, setReportName] = useState('');
  const [exportFormat, setExportFormat] = useState('csv');
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [scheduleName, setScheduleName] = useState('');
  const [scheduleFrequency, setScheduleFrequency] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [aggregations, setAggregations] = useState({});
  const [visualizationsEnabled, setVisualizationsEnabled] = useState(false);
  const [currentUser, setCurrentUser] = useState(null); // Placeholder for user data
  const [templateDataToLoad, setTemplateDataToLoad] = useState(null);

  const reportModelOptions = [
    'advisors',
    'availability',
    'groups',
    'resources',
    'studentAdvisors',
    'users',
  ];

  const db = getFirestore();

  // Fetch Templates on Component Mount
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templatesRef = collection(db, 'reportTemplates');
        const querySnapshot = await getDocs(templatesRef);
        const fetchedTemplates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTemplates(fetchedTemplates);
      } catch (error) {
        console.error('Error fetching templates:', error);
        toast.error('Failed to fetch templates.');
      }
    };

    fetchTemplates();
  }, [db]);

  // Function to fetch fields from the selected collection
  const getFieldsFromCollection = async (collectionName) => {
    setLoadingFields(true);
    try {
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);

      const allFields = new Set();

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        Object.keys(docData).forEach((field) => {
          allFields.add(field);
        });
      });

      setFields(Array.from(allFields));
    } catch (error) {
      console.error('Error fetching fields from Firestore:', error);
      toast.error('Failed to fetch fields. Please try again.');
    } finally {
      setLoadingFields(false);
    }
  };

  // Function to fetch sub-fields for a given field
  const getSubFields = async (field, collectionName) => {
    try {
      const collectionRef = collection(db, collectionName);
      const querySnapshot = await getDocs(collectionRef);

      const allSubFields = new Set();

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (docData[field] && typeof docData[field] === 'object') {
          Object.keys(docData[field]).forEach((subField) => {
            allSubFields.add(subField);
          });
        }
      });

      setAvailableSubFieldsMap((prev) => ({
        ...prev,
        [field]: Array.from(allSubFields),
      }));
    } catch (error) {
      console.error('Error fetching sub-fields:', error);
      toast.error('Failed to fetch sub-fields. Please try again.');
    }
  };

  // Handle selection of fields
  const handleFieldSelection = (newSelectedFields) => {
    setSelectedFields(newSelectedFields);

    // Fetch sub-fields for all selected fields
    newSelectedFields.forEach((field) => {
      if (!subFieldsMap[field] && !availableSubFieldsMap[field]) {
        getSubFields(field, reportModel);
      }
    });

    // Remove sub-fields for unselected fields
    setSubFieldsMap((prev) => {
      const updated = { ...prev };
      Object.keys(updated).forEach((field) => {
        if (!newSelectedFields.includes(field)) {
          delete updated[field];
        }
      });
      return updated;
    });
  };

  // Handle selection of sub-fields
  const handleSubFieldSelection = (field, newSelectedSubFields) => {
    setSubFieldsMap({
      ...subFieldsMap,
      [field]: newSelectedSubFields,
    });
  };

  // Fetch fields when the report model changes
  useEffect(() => {
    if (reportModel) {
      getFieldsFromCollection(reportModel);
    }
  }, [reportModel]);

  // Handle report model change
  const handleReportModelChange = (newModel) => {
    setReportModel(newModel);
    // Reset selections when changing the model manually
    setSelectedFields([]);
    setAvailableSubFieldsMap({});
    setSubFieldsMap({});
    setReportData([]);
    setReportColumns([]);
    setAggregations({});
    setVisualizationsEnabled(false);
  };

  // Load template data after fields are loaded
  useEffect(() => {
    if (templateDataToLoad && fields.length > 0) {
      // Ensure that fields include selectedFields
      const newFields = new Set([...fields, ...templateDataToLoad.selectedFields]);
      setFields(Array.from(newFields));

      setSelectedFields(templateDataToLoad.selectedFields);
      setSubFieldsMap(templateDataToLoad.subFieldsMap);

      // Fetch sub-fields for selected fields
      templateDataToLoad.selectedFields.forEach((field) => {
        if (!availableSubFieldsMap[field]) {
          getSubFields(field, templateDataToLoad.reportModel);
        }
      });
      setTemplateDataToLoad(null); // Reset
    }
  }, [fields, templateDataToLoad]);

  // Function to generate the report
  const handleCreateReport = async () => {
    if (!reportModel) {
      toast.error('Please select a report model.');
      return;
    }
    if (selectedFields.length === 0) {
      toast.error('Please select at least one field.');
      return;
    }

    setGeneratingReport(true);
    try {
      const collectionRef = collection(db, reportModel);
      const querySnapshot = await getDocs(collectionRef);

      const reportRows = [];
      const columns = [];

      selectedFields.forEach((field) => {
        if (subFieldsMap[field] && subFieldsMap[field].length > 0) {
          subFieldsMap[field].forEach((subField) => {
            const uniqueColumn = `${field}_${subField}`; // Ensure uniqueness
            columns.push(uniqueColumn);
          });
        } else {
          columns.push(field);
        }
      });

      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const row = {};

        selectedFields.forEach((field) => {
          if (
            subFieldsMap[field] &&
            subFieldsMap[field].length > 0 &&
            typeof docData[field] === 'object' &&
            docData[field] !== null
          ) {
            subFieldsMap[field].forEach((subField) => {
              const value = docData[field][subField];
              let formattedValue = 'N/A'; // Default value

              if (Array.isArray(value)) {
                // Join array elements into a comma-separated string
                formattedValue = value.join(', ') || 'N/A';
              } else if (value && typeof value === 'object') {
                if ('seconds' in value && 'nanoseconds' in value) {
                  // Firestore Timestamp
                  formattedValue = formatTimestamp(value);
                } else {
                  // Other objects - customize as needed
                  formattedValue = JSON.stringify(value) || 'N/A';
                }
              } else if (typeof value === 'string') {
                formattedValue = value.trim() !== '' ? value : 'N/A';
              } else if (value !== null && value !== undefined) {
                // For numbers and other types
                formattedValue = value;
              }

              row[`${field}_${subField}`] = formattedValue;
            });
          } else {
            const value = docData[field];
            let formattedValue = 'N/A'; // Default value

            if (Array.isArray(value)) {
              // Join array elements into a comma-separated string
              formattedValue = value.join(', ') || 'N/A';
            } else if (value && typeof value === 'object') {
              if ('seconds' in value && 'nanoseconds' in value) {
                // Firestore Timestamp
                formattedValue = formatTimestamp(value);
              } else {
                // Other objects - customize as needed
                formattedValue = JSON.stringify(value) || 'N/A';
              }
            } else if (typeof value === 'string') {
              formattedValue = value.trim() !== '' ? value : 'N/A';
            } else if (value !== null && value !== undefined) {
              // For numbers and other types
              formattedValue = value;
            }

            row[field] = formattedValue;
          }
        });

        reportRows.push(row);
      });

      setReportColumns(columns);
      setReportData(reportRows);
      computeAggregations(reportRows, columns);
      toast.success('Report generated successfully!');
      // Enable visualization options after report is generated
      setVisualizationsEnabled(false);
    } catch (error) {
      console.error('Error generating report:', error);
      toast.error('Failed to generate report. Please try again.');
    } finally {
      setGeneratingReport(false);
    }
  };

  // Function to compute aggregations
  const computeAggregations = (data, columns) => {
    const aggregations = {};

    columns.forEach(col => {
      const numericData = data.map(row => parseFloat(row[col])).filter(val => !isNaN(val));
      if (numericData.length > 0) {
        aggregations[col] = {
          count: numericData.length,
          sum: numericData.reduce((a, b) => a + b, 0),
          average: numericData.reduce((a, b) => a + b, 0) / numericData.length,
        };
      }
    });

    setAggregations(aggregations);
  };

  // Define colors for Pie Charts
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AA336A', '#33AA99', '#FF6666'];

  // Export Report Function
  const exportReport = () => {
    if (!reportName.trim()) {
      toast.error('Please enter a valid report name.');
      return;
    }

    switch (exportFormat) {
      case 'csv':
        exportToCSV();
        break;
      case 'excel':
        exportToExcel();
        break;
      case 'pdf':
        exportToPDF();
        break;
      default:
        exportToCSV();
    }
  };

  // Export to CSV
  const exportToCSV = () => {
    const headers = reportColumns;
    const rows = reportData.map((row) => headers.map((header) => `"${row[header]}"`));

    let csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers.join(','), ...rows.map((e) => e.join(','))].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, `${reportName.trim()}.csv`);
    setExportModalOpen(false);
    setReportName('');
    toast.success('Report exported successfully as CSV!');
  };

  // Export to Excel
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(reportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, `${reportName.trim()}.xlsx`);
    setExportModalOpen(false);
    setReportName('');
    toast.success('Report exported successfully as Excel!');
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text(reportName.trim(), 14, 16);
    const tableColumn = reportColumns.map(col => col.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())); // For better display
    const tableRows = reportData.map(row => reportColumns.map(col => row[col]));

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [22, 160, 133] },
    });

    doc.save(`${reportName.trim()}.pdf`);
    setExportModalOpen(false);
    setReportName('');
    toast.success('Report exported successfully as PDF!');
  };

  // Save Template Function
  const saveTemplate = async () => {
    if (!templateName.trim()) {
      toast.error('Please enter a valid template name.');
      return;
    }

    const templateData = {
      name: templateName.trim(),
      reportModel,
      selectedFields,
      subFieldsMap,
      createdAt: new Date(),
    };

    try {
      await addDoc(collection(db, 'reportTemplates'), templateData);
      toast.success('Template saved successfully!');
      setTemplateName('');
      // Refresh templates list
      const templatesRef = collection(db, 'reportTemplates');
      const querySnapshot = await getDocs(templatesRef);
      const fetchedTemplates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTemplates(fetchedTemplates);
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('Failed to save template.');
    }
  };

  // Load Template Function
  const loadTemplate = async (templateId) => {
    if (!templateId) return;
    try {
      const templateDoc = await getDoc(doc(db, 'reportTemplates', templateId));
      if (templateDoc.exists()) {
        const templateData = templateDoc.data();
        setReportModel(templateData.reportModel);
        setTemplateDataToLoad(templateData);
        toast.success(`Template "${templateData.name}" loaded.`);
      } else {
        toast.error('Template not found.');
      }
    } catch (error) {
      console.error('Error loading template:', error);
      toast.error('Failed to load template.');
    }
  };

  // Delete Template Function
  const deleteTemplate = async (templateId) => {
    if (!window.confirm('Are you sure you want to delete this template?')) return;

    try {
      await deleteDoc(doc(db, 'reportTemplates', templateId));
      toast.success('Template deleted successfully!');
      // Refresh templates list
      const templatesRef = collection(db, 'reportTemplates');
      const querySnapshot = await getDocs(templatesRef);
      const fetchedTemplates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTemplates(fetchedTemplates);
    } catch (error) {
      console.error('Error deleting template:', error);
      toast.error('Failed to delete template.');
    }
  };

  // Schedule Report Function
  const scheduleReport = async () => {
    if (!scheduleName.trim() || !scheduleFrequency || !scheduleTime) {
      toast.error('Please fill in all scheduling fields.');
      return;
    }

    const scheduleData = {
      name: scheduleName.trim(),
      frequency: scheduleFrequency,
      time: scheduleTime,
      reportModel,
      selectedFields,
      subFieldsMap,
      createdAt: new Date(),
      // Assuming user authentication is implemented and user ID is available
      // userId: currentUser.id,
    };

    try {
      await addDoc(collection(db, 'scheduledReports'), scheduleData);
      toast.success('Report scheduled successfully!');
      setScheduleName('');
      setScheduleFrequency('');
      setScheduleTime('');
    } catch (error) {
      console.error('Error scheduling report:', error);
      toast.error('Failed to schedule report.');
    }
  };

  // Memoize columns for React Table
  const columns = useMemo(() => 
    reportColumns.map(col => ({
      Header: col.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()), // For better display
      accessor: col,
      Filter: ColumnFilter,
    })), 
    [reportColumns]
  );

  // Memoize data for React Table
  const data = useMemo(() => reportData, [reportData]);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <CustomSidebar/>
      {/* Main content with flex layout */}
      <div className="flex-1 p-4 ml-20 flex flex-col">
        {/* Header Section remains unchanged */}
        <div className="text-center mb-4">
          <h1 className="text-3xl font-bold mb-2 mt-3">Reports</h1>
          <p className="text-base text-gray-600 mb-2">
            Generate custom reports by selecting a model, fields, and sub-fields. View and export the data in a structured table with visual insights.
          </p>
        </div>
        {/* Main Layout */}
        <div className="flex flex-col md:flex-row">
          {/* Filters Section */}
          <div className="md:w-1/4 md:pr-6 mb-6 md:mb-0">
            <div className="bg-white p-4 rounded-lg shadow">
              {/* Report Model Selection remains unchanged */}
              <div className="mb-4">
                <Label htmlFor="reportModel" className="block text-gray-700 font-semibold mb-2">
                  Report Model
                </Label>
                <select
                  id="reportModel"
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={reportModel}
                  onChange={(e) => handleReportModelChange(e.target.value)}
                >
                  <option value="">Select Model</option>
                  {reportModelOptions.map((collection) => (
                    <option key={collection} value={collection}>
                      {collection.charAt(0).toUpperCase() + collection.slice(1)}
                    </option>
                  ))}
                </select>
              </div>

              {/* Select Fields remains unchanged */}
              <div className="mb-2">
                <Label className="block text-gray-700 font-semibold mb-2">Select Fields</Label>
                {loadingFields ? (
                  <div className="flex items-center">
                    <Spinner size="sm" />
                    <span className="ml-2">Loading fields...</span>
                  </div>
                ) : (
                  <MultiSelect
                    suggestions={fields}
                    value={selectedFields}
                    onChange={handleFieldSelection}
                    placeholder="Type to search..."
                    required={false} // Set to true if at least one selection is required
                    unselectable={[]} // Add any unselectable options here if needed
                  />
                )}
              </div>

              {/* Select Sub-Fields for Each Selected Field remains unchanged */}
              {selectedFields.map((field) => (
                availableSubFieldsMap[field] && availableSubFieldsMap[field].length > 0 && (
                  <div className="mb-2" key={field}>
                    <Label className="block text-gray-700 font-semibold mb-2">
                      Select Sub-Fields for <span className="text-blue-600">{field}</span>
                    </Label>
                    <MultiSelect
                      suggestions={availableSubFieldsMap[field]}
                      value={subFieldsMap[field] || []}
                      onChange={(newSubFields) => handleSubFieldSelection(field, newSubFields)}
                      placeholder="Type to search..."
                      required={false}
                      unselectable={[]}
                    />
                  </div>
                )
              ))}

              {/* Action Buttons (Generate & Export) */}
              <div>
                <Button
                  onClick={handleCreateReport}
                  disabled={generatingReport}
                  className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 w-full"
                >
                  {generatingReport ? (
                    <>
                      <Spinner size="sm" light={true} className="mr-2" />
                      Generating...
                    </>
                  ) : (
                    'Generate Report'
                  )}
                </Button>
                <Button
                  color="gray"
                  onClick={() => setExportModalOpen(true)}
                  disabled={reportData.length === 0}
                  className="w-full flex items-center justify-center"
                >
                  <FaDownload className="mr-2" />
                  Export Report
                </Button>
              </div>

              {/* Save Template Section - Now Only Enabled After Report Generation */}
              <div className="mt-6">
                <h2 className="text-xl font-semibold mb-2">Report Templates</h2>
                <TextInput
                  type="text"
                  placeholder="Template Name"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  required
                  className="mr-2 mb-2"
                />
                <Button 
                  onClick={saveTemplate} 
                  disabled={!templateName.trim() || reportData.length === 0} 
                  className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 w-full"
                >
                  Save Template
                </Button>
                <Select
                  onChange={(e) => loadTemplate(e.target.value)}
                  defaultValue=""
                  className="mt-2 mb-2"
                >
                  <option value="" disabled>Select a Template</option>
                  {templates.map(template => (
                    <option key={template.id} value={template.id}>
                      {template.name}
                    </option>
                  ))}
                </Select>
                {templates.length > 0 && (
                  <div>
                    <h3 className="text-md font-semibold">Existing Templates:</h3>
                    <ul className="list-disc list-inside">
                      {templates.map(template => (
                        <li key={template.id} className="flex justify-between items-center">
                          <span>{template.name}</span>
                          <Button
                            size="xs"
                            color="failure"
                            onClick={() => deleteTemplate(template.id)}
                          >
                            Delete
                          </Button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              {/* Schedule Report Section - Remains visible only after report is generated */}
              {reportData.length > 0 && (
                <div className="mt-6">
                  <h2 className="text-xl font-semibold mb-2">Schedule Report</h2>
                  <Label htmlFor="scheduleName" className="block text-gray-700 font-semibold mb-2">
                    Schedule Name
                  </Label>
                  <TextInput
                    id="scheduleName"
                    type="text"
                    placeholder="Enter schedule name"
                    value={scheduleName}
                    onChange={(e) => setScheduleName(e.target.value)}
                    required
                    className="mb-4"
                  />

                  <Label htmlFor="scheduleFrequency" className="block text-gray-700 font-semibold mb-2">
                    Frequency
                  </Label>
                  <select
                    id="scheduleFrequency"
                    className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
                    value={scheduleFrequency}
                    onChange={(e) => setScheduleFrequency(e.target.value)}
                  >
                    <option value="">Select Frequency</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>

                  <Label htmlFor="scheduleTime" className="block text-gray-700 font-semibold mb-2">
                    Time
                  </Label>
                  <TextInput
                    id="scheduleTime"
                    type="time"
                    value={scheduleTime}
                    onChange={(e) => setScheduleTime(e.target.value)}
                    required
                    className="mb-2"
                  />

                  <Button 
                    onClick={scheduleReport} 
                    disabled={!reportModel || selectedFields.length === 0}
                    className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 w-full"
                  >
                    Schedule Report
                  </Button>
                </div>
              )}
            </div>
          </div>

          {/* Report Table and Visualizations Section remains unchanged */}
          <div className="md:w-3/4">
            <div className="bg-white p-4 rounded-lg shadow overflow-x-auto">
              {reportData.length > 0 ? (
                <div>
                  {/* Aggregation Summary remains unchanged */}
                  {Object.keys(aggregations).length > 0 && (
                    <div className="bg-gray-100 p-4 rounded-lg mb-6">
                      <h3 className="text-xl font-medium mb-2">Summary Statistics</h3>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        {Object.keys(aggregations).map((col, index) => (
                          <div key={index} className="p-4 bg-white rounded shadow">
                            <h4 className="text-lg font-semibold mb-1">{col.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</h4>
                            <p>Count: {aggregations[col].count}</p>
                            <p>Sum: {aggregations[col].sum}</p>
                            <p>Average: {aggregations[col].average.toFixed(2)}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Report Table remains unchanged */}
                  <ReportTable columns={columns} data={data} />

                  {/* Toggle Visualizations remains unchanged */}
                  <div className="mt-6 flex items-center">
                    <Checkbox
                      id="toggleVisualizations"
                      checked={visualizationsEnabled}
                      onChange={(e) => setVisualizationsEnabled(e.target.checked)}
                    />
                    <Label htmlFor="toggleVisualizations" className="ml-2">
                      Add Visualizations
                    </Label>
                  </div>

                  {/* Visualizations remains unchanged */}
                  {visualizationsEnabled && (
                    <div className="mt-6">
                      <h2 className="text-2xl font-semibold mb-4">Visualizations</h2>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Example: Bar Chart */}
                        <div className="bg-white p-4 rounded-lg shadow">
                          <h3 className="text-xl font-medium mb-2">Bar Chart</h3>
                          <ResponsiveContainer width="100%" height={300}>
                            <BarChart data={reportData}>
                              {reportColumns.map((col, index) => {
                                // Determine if the column is numeric
                                const isNumeric = typeof reportData[0][col] === 'number' || 
                                  !isNaN(parseFloat(reportData[0][col]));
                                return isNumeric ? (
                                  <Bar key={col} dataKey={col} fill={COLORS[index % COLORS.length]} />
                                ) : null;
                              })}
                              <XAxis dataKey={reportColumns[0]} />
                              <YAxis />
                              <Tooltip />
                              <Legend />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>

                        {/* Example: Pie Chart */}
                        <div className="bg-white p-4 rounded-lg shadow">
                          <h3 className="text-xl font-medium mb-2">Pie Chart</h3>
                          <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                              <Pie
                                data={reportData}
                                dataKey={reportColumns[0]}
                                nameKey={reportColumns[1]}
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#8884d8"
                                label
                              >
                                {reportData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend />
                            </PieChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex justify-center items-center h-64">
                  {generatingReport ? (
                    <div className="flex items-center">
                      <Spinner aria-label="Generating report" />
                      <span className="ml-2">Generating report...</span>
                    </div>
                  ) : (
                    <p className="text-gray-500">No report generated. Select fields and click "Generate Report".</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Export Report Modal remains unchanged */}
        <Modal
          show={exportModalOpen}
          size="md"
          popup={true}
          onClose={() => setExportModalOpen(false)}
        >
          <Modal.Header />
          <Modal.Body>
            <div className="space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Export Report
              </h3>
              <div>
                <Label htmlFor="reportName" className="block mb-2 text-sm font-medium text-gray-700">
                  Report Name
                </Label>
                <TextInput
                  id="reportName"
                  type="text"
                  placeholder="Enter report name"
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  required
                />
              </div>
              <div>
                <Label htmlFor="exportFormat" className="block mb-2 text-sm font-medium text-gray-700">
                  Export Format
                </Label>
                <Select
                  id="exportFormat"
                  value={exportFormat}
                  onChange={(e) => setExportFormat(e.target.value)}
                >
                  <option value="csv">CSV</option>
                  <option value="excel">Excel</option>
                  <option value="pdf">PDF</option>
                </Select>
              </div>
              <div className="flex justify-end space-x-4">
                <Button color="gray" onClick={() => setExportModalOpen(false)}>
                  Cancel
                </Button>
                <Button onClick={exportReport} disabled={!reportName.trim()}>
                  Export
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Reports;
