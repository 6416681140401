import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from '../../contexts/AuthContext';
import { ClassProvider } from '../../contexts/ClassContext';
import ProtectedRoutes from './ProtectedRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App({ offeredDegrees }) {
  // if (process.env.REACT_APP_TYPE === 'PROD') {
  //   console.log = function () {}; 
  // }
  
  return (
    <AuthProvider>
      <ClassProvider>
        <Router>
          <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: '100vh' }}
          >
            <ProtectedRoutes offeredDegrees={offeredDegrees} />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Container>
        </Router>
      </ClassProvider>
    </AuthProvider>
  );
}

export default App;
