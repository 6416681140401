// StudentItem.js
import React, { memo } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';

const StudentItem = memo(({ student, isSelected, onCheckboxChange, adjustment, selectedResource, handleRecommendResource }) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const handleRecommend = async (e) => {
    if (selectedResource && !student.resources?.includes(selectedResource.id)) {
      try {
        const studentRef = doc(db, 'users', student.id);
        await updateDoc(studentRef, {
          Resources: arrayUnion(selectedResource.id),
        });
        onCheckboxChange(student.id, true); // Update local state if needed
      } catch (error) {
        console.error('Error recommending resource:', error);
      }
    }
  };

  const resourceRecommended = selectedResource
    ? (student.resources || []).includes(selectedResource.id)
    : false;

  return (
    <div
      className="border bg-white rounded-lg p-1.5 mb-2 cursor-pointer hover:bg-gray-100"
      onClick={() => {
        if (adjustment !== 'Resources') {

        } else {
          if (!resourceRecommended) {
            handleRecommend();
          }
        }
      }}
    >
      <div className="grid grid-cols-12 items-center">
        {adjustment !== 'Resources' && (
          <div className="text-left col-span-1">
            <input
              type="checkbox"
              checked={isSelected}
              onClick={(e) => e.stopPropagation()} // Prevent event bubbling
              onChange={(e) => onCheckboxChange(student.id, e.target.checked)}
              className="ml-5 w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500"
            />
          </div>
        )}

        <div className={`text-left col-span-4`}>
          <div className="flex items-center">
            {student.photoURL ? (
              <img
                src={student.photoURL}
                alt="Profile"
                className="w-8 h-8 rounded-full mr-2"
              />
            ) : (
              <div className="w-8 h-8 rounded-full mr-2 flex items-center justify-center bg-gray-400 text-white font-semibold">
                {student.name
                  .split(' ')
                  .map((namePart) => namePart[0])
                  .join('')
                  .substring(0, 2)}
              </div>
            )}
            <div>
              <h4 className="font-bold text-black">{student.name}</h4>
              <p className="text-sm text-gray-500">{student.email}</p>
            </div>
          </div>
        </div>

        <div className="text-center text-sm col-span-3">
          {Array.isArray(student.major) ? student.major.join(', ') : student.major}
        </div>

        <div className="text-center text-sm col-span-2">
          {student.surveyAnswers?.SchoolEnd?.year || 'N/A'}
        </div>

        <div className="text-right col-span-2 mr-1">
          {adjustment !== 'Resources' ? (
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/Plan/${student.id}`);
              }}
              className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded text-xs px-2 py-1 transition-all duration-300 whitespace-nowrap"
            >
              Review Plan
            </button>
          ) : (
            <button
              onClick={(e) => {
                e.stopPropagation();
                if (!resourceRecommended) {
                  handleRecommendResource(student.id);
                }
              }}
              className={`${
                resourceRecommended
                  ? 'text-green-700 hover:bg-green-700 cursor-not-allowed'
                  : 'text-blue-700 hover:bg-blue-700'
              } hover:text-white border border-blue-700 focus:outline-none font-medium rounded text-xs px-2 py-1 transition-all duration-300 whitespace-nowrap`}
              disabled={resourceRecommended}
            >
              {resourceRecommended ? 'Recommended' : 'Recommend Resource'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
});

export default StudentItem;
