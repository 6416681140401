import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {HiChartPie, HiMenu, HiX} from 'react-icons/hi';
import { PiStudent } from 'react-icons/pi';
import { BsMap, BsGraphUp, BsPencilSquare, BsPersonCircle, BsSignpost2, BsCalendarCheck, BsTable, BsUser } from 'react-icons/bs';
import { FaUserGroup, FaUser } from 'react-icons/fa6';
import { MdOutlineDashboardCustomize } from "react-icons/md";
import ProfileDrawer from './ProfileDrawer';
import { IoMdArrowBack } from "react-icons/io";

export default function CustomSidebar({ adjustment }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleProfileDrawer = () => {
    setIsProfileDrawerOpen(!isProfileDrawerOpen);
  };

  return (
    <>
      {/* Blue Banner */}
      <div className="fixed top-0 left-0 w-full h-7 bg-blue-200 z-50 ml-10 flex items-center justify-center px-4 text-sm font-black text-blue-800">
      {adjustment === 'Student Plan' && (
          <Link to="/students" className="text-blue-800 mr-4 hover:underline">
            <IoMdArrowBack />
          </Link>
        )}
        <span>{adjustment || 'EDVISE'}</span>
        
      </div>

      {/* Sidebar Toggle Button (Visible on small screens) */}
      <button
        onClick={toggleSidebar}
        type="button"
        className="inline-flex items-center p-1 mt-1 text-xs text-white rounded-lg sm:hidden hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-200"
        aria-controls="logo-sidebar"
        aria-expanded={isSidebarOpen}
      >
        <span className="sr-only">Open sidebar</span>
        {isSidebarOpen ? (
          <HiX className="w-4 h-4" aria-hidden="true" />
        ) : (
          <HiMenu className="w-4 h-4" aria-hidden="true" />
        )}
      </button>

      {/* Sidebar */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-50 w-20 h-screen transition-transform bg-blue-800 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } sm:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-2 py-3 overflow-y-auto">
          {/* Logo */}
          <Link to="/Profile" className="flex items-center justify-center mb-2 mx-auto">
            <img
              src={`${process.env.PUBLIC_URL}/edviseicon.png`}
              className="h-10 me-1 sm:h-12"
              alt="Edvise Logo"
            />
          </Link>

          {/* Navigation Items */}
          <ul className="space-y-0 font-xs">
            {/* Dashboard */}
            <li>
              <Link
                to="/dashboard"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <MdOutlineDashboardCustomize className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Dashboard</span>
              </Link>
            </li>
            {/* Students */}
            <li>
              <Link
                to="/students"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <PiStudent className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Students</span>
              </Link>
            </li>
            {/* Pathways */}
            <li>
              <Link
                to="/add-pathways"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsMap className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Pathways</span>
              </Link>
            </li>
            {/* Courses */}
            <li>
              <Link
                to="/courses"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsPencilSquare className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Courses</span>
              </Link>
            </li>
            {/* Analytics */}
            <li>
              <Link
                to="/analytics"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsGraphUp className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Analytics</span>
              </Link>
            </li>
            {/* Resources */}
            <li>
              <Link
                to="/resources"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsSignpost2 className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Resources</span>
              </Link>
            </li>
            {/* Staff */}
            <li>
              <Link
                to="/staff"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <FaUserGroup className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Staff</span>
              </Link>
            </li>
            {/* Meetings */}
            <li>
              <Link
                to="/meetings"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsCalendarCheck className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Meetings</span>
              </Link>
            </li>
            {/* Reports */}
            <li>
              <Link
                to="/reports"
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <BsTable className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Reports</span>
              </Link>
            </li>
            {/* Profile */}
            <li>
              <Link
                onClick={toggleProfileDrawer}
                className="flex flex-col items-center p-2 text-white rounded-lg mr-1 hover:bg-blue-900 group"
              >
                <FaUser className="w-5 h-5 text-gray-300 transition duration-75 group-hover:text-white" />
                <span className="mt-1 text-[10px]">Profile</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>

      {/* Overlay for Sidebar on Mobile */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 z-30 bg-black opacity-50 sm:hidden"
          onClick={toggleSidebar}
          aria-hidden="true"
        ></div>
      )}
      {/* Profile Drawer */}
      <ProfileDrawer isOpen={isProfileDrawerOpen} onClose={toggleProfileDrawer} />
    </>
  );
}
