import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { useAuth } from '../../../contexts/AuthContext';
import { TextInput, Button, Spinner } from 'flowbite-react'; // Changed 'Input' to 'TextInput'
import { toast } from 'react-toastify';
import firebase from 'firebase/compat/app'; // Ensure compatibility
import CustomSidebar from '../Sidebar/Sidebar';

const AdvisorProfile = () => {
  const { currentUser } = useAuth();
  const [meetingLink, setMeetingLink] = useState('');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  // Fetch current meeting link
  useEffect(() => {
    const fetchMeetingLink = async () => {
      try {
        const advisorDoc = await db.collection('advisors').doc(currentUser.uid).get();
        if (advisorDoc.exists) {
          const data = advisorDoc.data();
          setMeetingLink(data.meetingLink || '');
        }
      } catch (error) {
        console.error('Error fetching advisor meeting link:', error);
        toast.error('Failed to fetch meeting link.');
      } finally {
        setLoading(false);
      }
    };

    if (currentUser) {
      fetchMeetingLink();
    }
  }, [currentUser]);

  const handleUpdateMeetingLink = async (e) => {
    e.preventDefault();
    setUpdating(true);
    try {
      await db.collection('advisors').doc(currentUser.uid).update({
        meetingLink,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });
      toast.success('Meeting link updated successfully!');
    } catch (error) {
      console.error('Error updating meeting link:', error);
      toast.error('Failed to update meeting link.');
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return <Spinner aria-label="Loading advisor profile" />;
  }

  return (
    <div className="max-w-md mx-auto mt-10">
    <CustomSidebar />
      <h2 className="text-xl font-semibold mb-4">Advisor Profile</h2>
      <form onSubmit={handleUpdateMeetingLink}>
        <div className="mb-4">
          <label htmlFor="meetingLink" className="block text-sm font-medium text-gray-700">
            Meeting Link (e.g., Zoom)
          </label>
          <TextInput
            id="meetingLink"
            type="url"
            value={meetingLink}
            onChange={(e) => setMeetingLink(e.target.value)}
            placeholder="https://zoom.us/j/1234567890"
            required
          />
        </div>
        <Button type="submit" disabled={updating}>
          {updating ? <Spinner size="sm" /> : 'Update Meeting Link'}
        </Button>
      </form>
    </div>
  );
};

export default AdvisorProfile;
