// AssignUsersModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'flowbite-react';
import { db } from '../../firebase';
import firebase from 'firebase/compat/app'; // Ensure compatibility
import { FaCheck, FaTimes } from 'react-icons/fa';

const AssignUsersModal = ({ show, onClose, groupId, existingMembers }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set(existingMembers));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  // Fetch all relevant users (exclude students) from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const snapshot = await db.collection('users').where('role', 'in', ['advisor', 'admin', 'super_admin']).get();
        const usersData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
      } catch (err) {
        setError('Error fetching users');
        console.error('Error fetching users:', err);
      }
    };

    fetchUsers();
  }, []);

  // Handle checkbox toggle
  const handleCheckboxChange = (userId) => {
    setSelectedUsers(prev => {
      const updated = new Set(prev);
      if (updated.has(userId)) {
        updated.delete(userId);
      } else {
        updated.add(userId);
      }
      return updated;
    });
  };

  // Handle assigning/removing users to/from the group
  const handleAssignUsers = async () => {
    setLoading(true);
    setError('');
    try {
      const batch = db.batch();
      const groupRef = db.collection('groups').doc(groupId);
      const arrayUnion = firebase.firestore.FieldValue.arrayUnion;
      const arrayRemove = firebase.firestore.FieldValue.arrayRemove;

      // Fetch current group data to determine additions and removals
      const groupSnapshot = await groupRef.get();
      const currentMembers = groupSnapshot.data().members || [];

      const membersToAdd = [...selectedUsers].filter(user => !currentMembers.includes(user));
      const membersToRemove = currentMembers.filter(user => !selectedUsers.has(user));

      // Update group's members
      if (membersToAdd.length > 0) {
        batch.update(groupRef, {
          members: firebase.firestore.FieldValue.arrayUnion(...membersToAdd),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }
      if (membersToRemove.length > 0) {
        batch.update(groupRef, {
          members: firebase.firestore.FieldValue.arrayRemove(...membersToRemove),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }

      // Update each user's groups
      // Add group to new users
      membersToAdd.forEach((userId) => {
        const userRef = db.collection('users').doc(userId);
        batch.update(userRef, {
          groups: arrayUnion(groupId),
        });
      });

      // Remove group from removed users
      membersToRemove.forEach((userId) => {
        const userRef = db.collection('users').doc(userId);
        batch.update(userRef, {
          groups: arrayRemove(groupId),
        });
      });

      // Commit the batch
      await batch.commit();

      // Close modal and reset state
      setSelectedUsers(new Set([...selectedUsers]));
      onClose();
    } catch (err) {
      setError('Error assigning users to group');
      console.error('Error assigning users:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onClose={onClose}>
      <Modal.Header>Assign Users to Group</Modal.Header>
      <Modal.Body>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="space-y-4 max-h-80 overflow-y-auto">
          {users.map((user) => (
            <div key={user.id} className="flex items-center">
              <input
                id={`user-${user.id}`}
                type="checkbox"
                checked={selectedUsers.has(user.id)}
                onChange={() => handleCheckboxChange(user.id)}
                className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor={`user-${user.id}`} className="ml-2 block text-sm text-gray-700">
                {user.firstName} {user.lastName} ({user.role})
              </label>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleAssignUsers}
          disabled={loading}
          color="blue"
        >
          {loading ? 'Saving...' : 'Save Changes'}
        </Button>
        <Button
          onClick={onClose}
          color="gray"
          variant="secondary"
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AssignUsersModal;
