import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for portals
import { TextInput } from 'flowbite-react'; // Import Switch for toggle
import { FaFilter, FaPlus, FaSort, FaCheck } from 'react-icons/fa';
import { useAuth } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import { db } from '../../firebase';
import StudentItem from './StudentItem'
import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
} from 'firebase/firestore'; // Import Firestore functions
import MultiSelect from '../../ClassSearch/MultiSelect';
import { FaTimes } from 'react-icons/fa';
import CustomSidebar from '.././Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

const Modal = ({ isVisible, onClose, children }) => {
  if (!isVisible) return null;

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 justify-center items-center">
      {/* Backdrop with Blur */}
      <div
        className="fixed inset-0 bg-opacity-30 backdrop-blur-sm"
        onClick={onClose}
      ></div>
      {/* Modal content with rounded corners and max height */}
      <div
        className="relative p-4 w-full max-w-3xl max-h-[80vh] z-50 overflow-y-auto rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>,
    document.body
  );
};

const SearchComponent = ({
  onSearch,
  students,
  onFilter,
  onSelectAll,
  selectedStudents,
  onToggleMyStudents,
  isMyStudents,
  onSort,
  filteredStudentsCount,
  fieldTypes,
  everythingSelected
}) => {
  const {
    currentUser,
    isUserDataFetched,
    fetchUserData,
    fetchedCourseData,
    fetchedPrereqData,
    majorData,
    fetchedMajorRecs,
    fetchedMinors,
    fetchedMajors,
    pathwayGroupingsData
  } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [transformedMajorRecs, setTransformedMajorRecs] = useState([]);
  const [availableFilters, setAvailableFilters] = useState({
    gradYear: [],
    program: [],
    advisors: [],
    performance: [],
  });
  const [selectedFilters, setSelectedFilters] = useState({
    gradYear: [],
    program: [],
    advisors: [],
    performance: [],
  });
  const [operatorError, setOperatorError] = useState('');

  // New state variables for the custom filter
  const [selectedField, setSelectedField] = useState('');
  const [selectedOperator, setSelectedOperator] = useState('');
  const [filterValue, setFilterValue] = useState('');

  // Generate the list of student fields dynamically
  const studentFields = Object.keys(fieldTypes);
  const [isFilterBarVisible, setIsFilterBarVisible] = useState(false);
  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);
  const [isSemesterSelectionVisible, setIsSemesterSelectionVisible] = useState(
    false
  );
  const [completedSemesters, setCompletedSemesters] = useState([]);
  const [selectedMajor, setSelectedMajor] = useState(null);
  const [allCourses, setAllCourses] = useState(fetchedCourseData);
  const [numYears, setNumYears] = useState(4);
  const [templateName, setTemplateName] = useState('');
  const [majorCourses, setMajorCourses] = useState([]);
  const [majorElectives, setMajorElectives] = useState([]);
  const [selectedTerms, setSelectedTerms] = useState([]);
  const [templateNameError, setTemplateNameError] = useState(false);
  const [isAutofilling, setIsAutofilling] = useState(false);
  const [majorRequirementCourses, setMajorRequirementCourses] = useState([]);
  const [selectedGradYears, setSelectedGradYears] = useState([]); // State for selected graduation years
  const [selectedPrograms, setSelectedPrograms] = useState([]); // State for selected programs
  const [selectedAdvisors, setSelectedAdvisors] = useState([]); // State for selected advisors
  const [selectedPerformance, setSelectedPerformance] = useState([]); // State for selected performance
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredMajors, setFilteredMajors] = useState(fetchedMajors);

const handleSelectAllClick = () => {
  onSelectAll();
};

  useEffect(() => {
    onFilter({
      ...selectedFilters,
      customFilter: {
        field: selectedField,
        operator: selectedOperator,
        value: filterValue,
        error: operatorError,
      },
    });
  }, [
    selectedFilters,
    selectedField,
    selectedOperator,
    filterValue,
    operatorError,
    onFilter,
  ]);

  useEffect(() => {
    if (fetchedMajors) {
      // Filter majors based on the search query (case insensitive)
      const filtered = Object.entries(fetchedMajors)
        .filter(([major]) => major.toLowerCase().includes(searchQuery.toLowerCase())) // Filter based on the search query
        .reduce((acc, [major, value]) => {
          acc[major] = value; // Keep the entire object structure
          return acc;
      }, {});
      setFilteredMajors(filtered); // Update the filtered list
    }
  }, [searchQuery, fetchedMajors]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value); // Update search query on input change
  };


  const fetchFilters = useCallback(async () => {
    try {
      const response = await fetch('/filters.json');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setAvailableFilters(data);
    } catch (error) {
      console.error('Error fetching filters:', error);
    }
  }, []);

  const generateInitialState = (numYears) => {
    const yearObject = {};

    for (let i = 1; i <= numYears; i++) {
      yearObject[`year${i}`] = {
        Fall: [],
        Spring: [],
      };
    }

    return yearObject;
  };

  const calculateRecScore = require('../../CourseRecommendation');

  const fetchTransformedMajorRecs = () => {
    let transformedMajorRecs = {};

    for (let i = 0; i < fetchedMajorRecs.length; i++) {
      const curr = fetchedMajorRecs[i];
      const { Major, ...categories } = curr;
      transformedMajorRecs[Major] = categories;
    }

    setTransformedMajorRecs(transformedMajorRecs);
  };

  useEffect(() => {
    if (fetchedMajorRecs.length > 0) {
      fetchTransformedMajorRecs();
    }
  }, [fetchedMajorRecs]);

  const parsePrerequisites = (prerequisites) => {
    let arrayOfPrerequisites = prerequisites.split('&');
    arrayOfPrerequisites = arrayOfPrerequisites.map((element) =>
      element.replace(/\(|\)/g, '')
    );
    return arrayOfPrerequisites.map((element) => element.split('||'));
  };

  const parseCorequisites = (corequisites) => {
    let arrayOfCorequisites = corequisites.split('&');
    arrayOfCorequisites = arrayOfCorequisites.map((element) =>
      element.replace(/\(|\)/g, '')
    );
    return arrayOfCorequisites.map((element) => element.split('||'));
  };

  const checkPrerequisites = (course, yearKey, semesterKey, cumulativeIds) => {
    let isPrereqMet = true;
    let isCoreqMet = true;

    if (
      course.Prerequisites &&
      course.Prerequisites[0] &&
      course.Prerequisites[0][0]
    ) {
      isPrereqMet = course.Prerequisites.every((prereqGroup) =>
        prereqGroup.some((prereq) => {
          if (
            yearKey === 'year1' &&
            (semesterKey === 'S1' || semesterKey === 'Fall')
          ) {
            return cumulativeIds['year0'].includes(prereq);
          } else if (semesterKey === 'S1' || semesterKey === 'Fall') {
            const lastYear = parseInt(yearKey.charAt(4)) - 1;
            return cumulativeIds[`year${lastYear}`]['S2'].includes(prereq);
          } else {
            return cumulativeIds[yearKey]['S1'].includes(prereq);
          }
        })
      );
    }

    if (
      course.Corequisites &&
      course.Corequisites[0] &&
      course.Corequisites[0][0]
    ) {
      let semesterCode = semesterKey === 'Fall' ? 'S1' : 'S2';
      isCoreqMet = course.Corequisites.some((coreqGroup) => {
        return coreqGroup.some((coreq) => {
          return cumulativeIds[yearKey][semesterCode].includes(coreq);
        });
      });
    }

    const isTotalMet = isPrereqMet && isCoreqMet;

    return isTotalMet;
  };

  const recheckPrerequisites = useCallback((updatedOrganizedCourses) => {
    let cumulativeIds = { year0: [] };
    let prevYearsCourses = [];

    for (let i = 1; i <= numYears; i++) {
      let year = `year${i}`;
      let yearCourses = {};
      let cumulativeCourses = prevYearsCourses;

      for (let j = 1; j <= 2; j++) {
        let semester = `S${j}`;
        let semesterCode = semester === 'S1' ? 'Fall' : 'Spring';

        if (
          updatedOrganizedCourses &&
          updatedOrganizedCourses[year] &&
          updatedOrganizedCourses[year][semesterCode]
        ) {
          cumulativeCourses = cumulativeCourses.concat(
            updatedOrganizedCourses[year][semesterCode].map(
              (course) => course.courseInfo_courseNumber
            )
          );
        }
        cumulativeCourses = Array.from(new Set(cumulativeCourses));
        yearCourses[semester] = [...cumulativeCourses];
      }

      prevYearsCourses = prevYearsCourses.concat(cumulativeCourses);
      prevYearsCourses = Array.from(new Set(prevYearsCourses));
      cumulativeIds[year] = yearCourses;
    }

    const updateObject = { ...updatedOrganizedCourses };

    for (const yearKey in updateObject) {
      ['Fall', 'Spring'].forEach((semesterKey) => {
        const semesterClassesObject = updateObject[yearKey][semesterKey] || [];

        updateObject[yearKey][semesterKey] = semesterClassesObject.map(
          (course) => {
            const courseInfo = fetchedPrereqData.find(
              (prereq) => prereq.Course === course.courseInfo_courseNumber
            );

            if (courseInfo) {
              course.Prerequisites = parsePrerequisites(
                courseInfo.Prerequisites
              );
              course.Corequisites = parseCorequisites(courseInfo.Corequisites);
            }

            course.isPrereqMet = checkPrerequisites(
              course,
              yearKey,
              semesterKey,
              cumulativeIds
            );

            return { ...course };
          }
        );
      });
    }

    setFinalizedOrganizedCourses(updateObject);
    return updateObject;
  }, []);

  const organizeCoursesByYearAndSemester = useCallback(
    (courseIds, allCourses, prereqs, cumulativeIds) => {
      const coursesByYearAndSemester = {};

      for (const yearKey in courseIds) {
        const yearObj = courseIds[yearKey];

        for (const semesterKey in yearObj) {
          const semesterIds = yearObj[semesterKey].courses;
          const semesterClasses = Array.isArray(semesterIds)
            ? allCourses.filter((course) =>
                semesterIds.includes(course.courseInfo_courseNumber)
              )
            : [];

          semesterClasses.forEach((course) => {
            const courseInfo = fetchedPrereqData.find(
              (prereq) => prereq.Course === course.courseInfo_courseNumber
            );
            if (courseInfo) {
              course.Prerequisites = parsePrerequisites(
                courseInfo.Prerequisites
              );
              course.Corequisites = parseCorequisites(courseInfo.Corequisites);
            }
            course.isPrereqMet = checkPrerequisites(
              course,
              yearKey,
              semesterKey,
              cumulativeIds
            );
          });

          const semesterName = semesterKey === 'S1' ? 'Fall' : 'Spring';
          if (!coursesByYearAndSemester[yearKey]) {
            coursesByYearAndSemester[yearKey] = { Fall: [], Spring: [] };
          }
          coursesByYearAndSemester[yearKey][semesterName].push(...semesterClasses);
        }
      }

      return coursesByYearAndSemester;
    },
    []
  );

  const fetchMajorRequirementCourses = (major) => {
    try {
      
      const majorRequirementsData = {};

      const calculateMaxYears = (courses) =>
        Math.max(...courses.map((course) => course.courseYear));

      const semesterMap = {
        S1: 'Fall',
        S2: 'Spring',
      };

      if (fetchedMajors[major]) {
        const majorCoursesFromBackend = Object.values(fetchedMajors[major]);

        const majorRequirementsMap = {};

        const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);

        for (let i = 1; i <= maxMajorYears; i++) {
          majorRequirementsMap[`year${i}`] = {
            Fall: [],
            Spring: [],
          };
        }

        majorCoursesFromBackend.forEach((course) => {
          if (
            course.courseType === 'Requirement' ||
            (course.courseType === 'Major Course' && course.Requirements !== '')
          ) {
            if (course.courseYear <= maxMajorYears) {
              const semester = semesterMap[course.CourseSem] || course.CourseSem;
              majorRequirementsMap[`year${course.courseYear}`][semester].push(
                course
              );
            }
          }
        });

        majorRequirementsData[major] = majorRequirementsMap;
        console.log('Fetched Major Requirements: ', majorRequirementsData);

        setMajorRequirementCourses(majorRequirementsData);

        return majorRequirementsData
      } else {
        console.error('Current user or major is not defined.');
        return null
      }
    } catch (error) {
      console.error('Error fetching major requirement courses:', error);
      return null
    }
  };

  const fetchMajorElectives = (major) => {
    try {
      const majorElectivesData = {};

      const calculateMaxYears = (courses) =>
        Math.max(...courses.map((course) => course.courseYear));

      const semesterMap = {
        S1: 'Fall',
        S2: 'Spring',
      };

      if (fetchedMajors[major]) {
        const majorCoursesFromBackend = Object.values(fetchedMajors[major]);

        const electiveCoursesMap = {};

        const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);

        for (let i = 1; i <= maxMajorYears; i++) {
          electiveCoursesMap[`year${i}`] = {
            Fall: [],
            Spring: [],
          };
        }

        majorCoursesFromBackend.forEach((course) => {
          if (course.courseType === 'Elective') {
            if (course.courseYear <= maxMajorYears) {
              const semester = semesterMap[course.CourseSem] || course.CourseSem;
              electiveCoursesMap[`year${course.courseYear}`][semester].push(
                course
              );
            }
          }
        });

        majorElectivesData[major] = electiveCoursesMap;

        setMajorElectives(majorElectivesData);
        console.log('Fetched Major Electives Data:', majorElectivesData);
        return majorElectivesData
      } else {
        console.error('Current user or major is not defined.');
        return null
      }
    } catch (error) {
      console.error('Error fetching major electives:', error);
      return null
    }
  };

  const fetchMajorCourses = (major) => {
    try {
      const majorCoursesData = {};

      const calculateMaxYears = (courses) =>
        Math.max(...courses.map((course) => course.courseYear));

      const semesterMap = {
        S1: 'Fall',
        S2: 'Spring',
      };

      if (fetchedMajors[major]) {
        const majorCoursesFromBackend = Object.values(fetchedMajors[major]);

        const majorCoursesMap = {};

        const maxMajorYears = calculateMaxYears(majorCoursesFromBackend);

        for (let i = 1; i <= maxMajorYears; i++) {
          majorCoursesMap[`year${i}`] = {
            Fall: [],
            Spring: [],
          };
        }

        majorCoursesFromBackend.forEach((course) => {
          if (course.courseType === 'Major Course') {
            if (course.courseYear <= maxMajorYears) {
              const semester = semesterMap[course.CourseSem] || course.CourseSem;
              majorCoursesMap[`year${course.courseYear}`][semester].push(
                course
              );
            }
          }
        });

        majorCoursesData[major] = majorCoursesMap;

        setMajorCourses(majorCoursesData);

        console.log('Fetched Major Courses Data:', majorCoursesData);

        return majorCoursesData;

      } else {
        console.error('Current user or major is not defined.');
        return null
      }
    } catch (error) {
      console.error('Error fetching major courses:', error);
      return null
    }
  };

  const [finalizedOrganizedCourses, setFinalizedOrganizedCourses] = useState(
    generateInitialState(numYears)
  );

  const handleAutofillCourse = async (
    yearNumber,
    semester,
    major,
    user,
    templateName,
    numYearsNew,
    fetchedMajorElectives,
    fetchedMajorRequirements,
    fetchedMajorCourses
  ) => {
    const currentUser = user;
    console.log('Current user:', currentUser);
    const newScheduleName = templateName;

    const loadingToastId = 'autofill-course-toast';

    fetchedMajorElectives = fetchMajorElectives(major)

    fetchedMajorRequirements = fetchMajorRequirementCourses(major)

    fetchedMajorCourses = fetchMajorCourses(major)

    try {
      if (currentUser) {
        const userRef = db.collection('users').doc(user);
        const userDoc = await userRef.get();
        console.log('User ref test: ', userRef);
        await db.runTransaction(async (transaction) => {
          const userData = userDoc.data();
          console.log('User Data: ', userData);
          // fetchMajorCoursesData(userData);

          let semesterCode = semester === 'Fall' ? 'S1' : 'S2';

          const isComplete = userData?.completedSemesters?.[`year${yearNumber}`]?.[semesterCode]

          // Case in which the semetser is completed, take data from first other schedule
          if (isComplete) {
            if (Object.keys(userData?.schedules).length > 0) {
              const firstScheduleName = Object.keys(userData?.schedules)[0];
              const completedSemesterData = userData?.schedules?.[firstScheduleName]?.[`year${yearNumber}`]?.[semesterCode].courses;
              transaction.update(userRef, {
                [`schedules.${newScheduleName}.year${yearNumber}.${semesterCode}.courses`]:
                  completedSemesterData,
                });
              return
            } else {
              return
            }
          }


          const previousFinalizedSchedule =
            userData.schedules[newScheduleName] || {};

          const updateObj = previousFinalizedSchedule
            ? { ...previousFinalizedSchedule }
            : {};

          if (!updateObj[`year${yearNumber}`]) {
            updateObj[`year${yearNumber}`] = {};
          }
          if (!updateObj[`year${yearNumber}`][semesterCode]) {
            updateObj[`year${yearNumber}`][semesterCode] = { courses: [] };
          }

          const existingFinalizedSchedule =
            updateObj[`year${yearNumber}`][semesterCode].courses;

          const allFinalizedCourses = new Set();
          for (const year in finalizedOrganizedCourses) {
            for (const sem in finalizedOrganizedCourses[year]) {
              finalizedOrganizedCourses[year][sem].forEach((course) => {
                allFinalizedCourses.add(course.courseInfo_courseNumber);
              });
            }
          }

          let allClassNumbers = [];

          for (const year in updateObj) {
            for (const semester in updateObj[year]) {
              allClassNumbers = allClassNumbers.concat(
                updateObj[year][semester].courses
              );
            }
          }

          const allAutofillableCourses = allCourses.filter(
            (course) =>
              course.courseYear <= 4 &&
              course.Offering.includes(semester) &&
              !allFinalizedCourses.has(course.courseInfo_courseNumber) &&
              course.isPrereqMet
          );

          // Major Courses
          const combinedSemesterMajorCourses = [];

          Object.entries(fetchedMajorCourses).forEach(([majorName, courseInfo]) => {
            /*const organizedMajorCourses = organizeMajorCoursesByYearAndSemester(
              courseInfo
            );*/
            const organizedMajorCourses = courseInfo;
            if (organizedMajorCourses && organizedMajorCourses[`year${yearNumber}`]) {
              combinedSemesterMajorCourses.push(
                ...(organizedMajorCourses[`year${yearNumber}`][semester] || [])
              );
            }
          });

          let semesterMajorCourses =
            combinedSemesterMajorCourses.length > 0
              ? combinedSemesterMajorCourses
              : [];

          const semesterMajorCoursesWithAutofill = semesterMajorCourses.map(
            (course) => ({
              ...course,
              autofillType: 'Major Course',
            })
          );

          // Major Electives
          let combinedElectiveCourses = [];

          Object.entries(fetchedMajorElectives).forEach(
            ([majorName, electivesByMajor]) => {
              if (electivesByMajor && electivesByMajor[`year${yearNumber}`]) {
                combinedElectiveCourses.push(
                  ...electivesByMajor[`year${yearNumber}`][semester]
                );
              }
            }
          );

          const electiveNameStrings = combinedElectiveCourses.map(
            (course) => course.Possibilities
          );

          const semesterElectiveRequirements = electiveNameStrings.reduce(
            (acc, name) => {
              if (!acc[name]) {
                const course = combinedElectiveCourses.find(
                  (course) => course.Possibilities === name
                );
                const electiveFullName = course ? course.Requirements : '';
                acc[name] = {
                  quantity: 0,
                  electiveFullName: electiveFullName,
                };
              }
              acc[name].quantity += 1;

              return acc;
            },
            {}
          );

          let autofillElectiveCourses = [];

          Object.entries(semesterElectiveRequirements).forEach(
            ([requirementName, { quantity, electiveFullName }]) => {
              const existingCourses = existingFinalizedSchedule
                .map((courseNumber) =>
                  allCourses.find(
                    (course) =>
                      course.courseInfo_courseNumber === courseNumber
                  )
                )
                .filter(
                  (course) =>
                    course &&
                    course.Elective_fulfillment.includes(requirementName)
                );

              const remainingQuantity = quantity - existingCourses.length;

              if (remainingQuantity > 0) {
                const filteredCourses = allAutofillableCourses.filter(
                  (course) =>
                    course.Elective_fulfillment.includes(requirementName) &&
                    !allClassNumbers.includes(course.courseInfo_courseNumber)
                );

                const sortedCourses = filteredCourses.sort(
                  (a, b) => b.recScore - a.recScore
                );

                const topCourses = sortedCourses
                  .slice(0, remainingQuantity)
                  .map((course) => ({
                    ...course,
                    electiveRequirement: requirementName,
                    electiveFullName,
                  }));

                autofillElectiveCourses.push(...topCourses);
              }
            }
          );

          const autofillElectiveCoursesWithAutofill =
            autofillElectiveCourses.map((course) => ({
              ...course,
              autofillType: 'Elective',
            }));


          // Major Requirements
          let combinedRequirementCourses = [];

          Object.entries(fetchedMajorRequirements).forEach(
            ([majorName, requirementsByMajor]) => {
              if (
                requirementsByMajor &&
                requirementsByMajor[`year${yearNumber}`]
              ) {
                combinedRequirementCourses.push(
                  ...requirementsByMajor[`year${yearNumber}`][semester]
                );
              }
            }
          );

          const requirementNameStrings = combinedRequirementCourses.map(
            (course) => course.Requirements
          );

          const semesterRequirementDetails = requirementNameStrings.reduce(
            (acc, name) => {
              if (!acc[name]) {
                const course = combinedRequirementCourses.find(
                  (course) => course.Requirements === name
                );
                const possibleCourses = course
                  ? course.Possibilities.split(',').map((course) =>
                      course.trim()
                    )
                  : [];
                acc[name] = {
                  quantity: 0,
                  possibleCourses: possibleCourses,
                };
              }
              acc[name].quantity += 1;

              return acc;
            },
            {}
          );

          let autofillRequirementCourses = [];

          Object.entries(semesterRequirementDetails).forEach(
            ([requirementName, { quantity, possibleCourses }]) => {
              const commonElementsCount = possibleCourses.filter((course) =>
                existingFinalizedSchedule.includes(course)
              ).length;

              const remainingQuantity = quantity - commonElementsCount;

              if (remainingQuantity > 0) {
                const filteredCourses = possibleCourses
                  .map((courseNumber) =>
                    allAutofillableCourses.find(
                      (course) =>
                        course.courseInfo_courseNumber === courseNumber &&
                        !allClassNumbers.includes(courseNumber)
                    )
                  )
                  .filter((course) => course !== undefined);

                const sortedCourses = filteredCourses.sort(
                  (a, b) => b.recScore - a.recScore
                );

                const topCourses = sortedCourses
                  .slice(0, remainingQuantity)
                  .map((course) => ({
                    ...course,
                    requirementName: requirementName,
                  }));

                autofillRequirementCourses.push(...topCourses);
              }
            }
          );

          const autofillRequirementCoursesWithAutofill =
            autofillRequirementCourses.map((course) => ({
              ...course,
              autofillType: 'Requirement',
            }));

          // Combine all autofill courses, excluding minor courses
          const semesterAutofillCourses = [
            ...semesterMajorCoursesWithAutofill,
            ...autofillElectiveCoursesWithAutofill,
            ...autofillRequirementCoursesWithAutofill,
            // Minor courses are excluded
          ];

          const uniqueAutofillCourses = semesterAutofillCourses.reduce(
            (acc, current) => {
              const x = acc.find(
                (item) =>
                  item.courseInfo_courseNumber ===
                  current.courseInfo_courseNumber
              );
              if (!x) {
                acc.push(current);
              }
              return acc.filter(
                (course) =>
                  !allClassNumbers.includes(course.courseInfo_courseNumber)
              );
            },
            []
          );

          const semesterAutofillCoursesWithStatus = uniqueAutofillCourses.map(
            (course) => ({
              ...course,
              isAutofill: true,
            })
          );

          const semesterAutofillCourseIds = semesterAutofillCoursesWithStatus.map(
            (course) => course.courseInfo_courseNumber
          );
          const updatedFinalizedSchedule = [
            ...new Set([
              ...existingFinalizedSchedule,
              ...semesterAutofillCourseIds,
            ]),
          ];
          updateObj[`year${yearNumber}`][semesterCode].courses =
            updatedFinalizedSchedule;
            updateObj.numYears = numYearsNew;
            

          transaction.update(userRef, {
            [`schedules.${newScheduleName}.year${yearNumber}.${semesterCode}.courses`]:
              updatedFinalizedSchedule,
          });

          setFinalizedOrganizedCourses((prev) => {
            const newCourses = { ...prev };

            const existingCourseNumbers =
              newCourses?.[`year${yearNumber}`]?.[semester]?.map(
                (course) => course?.courseInfo_courseNumber
              ) ?? [];

            const filteredCourses = semesterAutofillCoursesWithStatus.filter(
              (course) =>
                !existingCourseNumbers.includes(course.courseInfo_courseNumber)
            );

            // Ensure newCourses[`year${yearNumber}`] is defined
            newCourses[`year${yearNumber}`] =
              newCourses[`year${yearNumber}`] ?? {};

            // Ensure newCourses[`year${yearNumber}`][semester] is defined
            newCourses[`year${yearNumber}`][semester] =
              newCourses[`year${yearNumber}`][semester] ?? [];

            // Now safely update newCourses[`year${yearNumber}`][semester]
            newCourses[`year${yearNumber}`][semester] = [
              ...new Set([
                ...newCourses[`year${yearNumber}`][semester],
                ...filteredCourses,
              ]),
            ];

            const updatedCourses = recheckPrerequisites(newCourses);

            return updatedCourses;
          });

          console.log('Courses Autofilled Successfully.');
          // Update the toast to success status
          setTimeout(() => {
            toast.update(loadingToastId, {
              render: 'Applied Template',
              type: 'success',
              autoClose: 1000,
              isLoading: false,
              hideProgressBar: true,
              style: {
                width: '12rem',
              },
            });
          }, 500); // Delay the transition by 500ms to keep the loading state visible
        });
      } else {
        console.error('Error: currentUser or currentUser.uid is not defined');
      }
    } catch (error) {
      // Show an error toast if the operation fails
      setTimeout(() => {
        toast.update(loadingToastId, {
          render: 'Failed apply template.',
          type: 'error',
          autoClose: 3000,
          isLoading: false,
          style: {
            width: '20rem',
          },
        });
      }, 500);
      console.error('Error autofilling courses:', error);
    }
  };

  const handleAutofillVariableCourses = async (
    selectedTerms,
    major,
    users,
    templateName
  ) => {
    setIsAutofilling(true);
    const fetchedMajorElectives = fetchMajorElectives(major);
    const fetchedMajorRequirements = fetchMajorRequirementCourses(major);
    const fetchedMajorCourses = fetchMajorCourses(major);
    try {
      const autofillPromises = users.flatMap((userId) =>
        selectedTerms.map((term) => {
          // Calculate year and semester based on term number (1 to 8)
          const yearNumber = Math.ceil(term / 2); // Dividing term by 2 and rounding up gives the year
          const semester = term % 2 === 1 ? 'Fall' : 'Spring'; // Odd terms are Fall (S1), even terms are Spring (S2)
          const semesterCode = semester === 'Fall' ? 'S1' : 'S2';
          
          if (true) {
            return handleAutofillCourse(
              yearNumber,
              semester,
              major,
              userId,
              templateName,
              fetchedMajorElectives,
              fetchedMajorRequirements,
              fetchedMajorCourses
            );
          }
          return Promise.resolve();
        })
      );
  
      await Promise.all(autofillPromises);

      toast.info(`Successfully added the ${major} Pathway to selected students.`)

    } catch (error) {
      toast.error('Failed to autofill selected terms.');
      console.error('Error autofilling courses:', error);
    } finally {
      setIsAutofilling(false);
    }
  };  

  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  useEffect(() => {
    if (searchTerm !== '') {
      onSearch(searchTerm);
    }
  }, [searchTerm, onSearch]);

  useEffect(() => {
    onFilter(selectedFilters);
  }, [selectedFilters, onFilter]);

  const toggleFilterBarVisibility = () => {
    setIsFilterBarVisible(!isFilterBarVisible);
  };

  const toggleTemplateModalVisibility = () => {
    if (selectedStudents.length == 0){
      toast.warning('Please select student(s) to add a template to.');
    }
    else {
      setIsTemplateModalVisible(!isTemplateModalVisible);
    }
  };

  const handleMajorSelection = (major) => {
    setSelectedMajor(major);
    setIsSemesterSelectionVisible(true);
  };

  const toggleYearSelection = (year) => {
    setSelectedTerms((prevSelectedTerms) =>
      prevSelectedTerms.includes(year)
        ? prevSelectedTerms.filter((y) => y !== year)
        : [...prevSelectedTerms, year]
    );
  };

  const renderModalContent = () => {
    const nMap = {
      1: `Template Term 1`,
      2: 'Template Term 2',
      3: 'Template Term 3',
      4: 'Template Term 4',
      5: 'Template Term 5',
      6: 'Template Term 6',
      7: 'Template Term 7',
      8: 'Template Term 8',
    };

    const yMap = {
      1: `Fall 2024`,
      2: 'Spring 2025',
      3: 'Fall 2025',
      4: 'Spring 2026',
      5: 'Fall 2026',
      6: 'Spring 2027',
      7: 'Fall 2027',
      8: 'Spring 2028',
    };

    if (!isSemesterSelectionVisible) {
      return (
        <div className="grid grid-cols-1 gap-3">
          {Object.keys(filteredMajors || {}).map((major) => (
            <div
              key={major}
              className="p-2.5 border rounded text-left cursor-pointer hover:bg-gray-100"
              onClick={() => handleMajorSelection(major)}
            >
              {major}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <>
          {/* Template Name Input */}
          <div className="mb-4">
            <input
              type="text"
              id="templateName"
              className={`mt-1 mb-1 block w-full px-3 py-2 bg-white border ${
                templateNameError ? 'border-red-500' : 'border-gray-300'
              } rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
              placeholder="Enter template name for students"
              value={templateName}
              onChange={handleTemplateNameChange}
            />
            {templateNameError && (
              <p className="text-red-500 text-xs mt-1">
                Template name is required.
              </p>
            )}
          </div>

          {/* Year Selection Buttons */}
          <div className="grid grid-cols-2 gap-4">
            {[...Array(8)].map((_, i) => {
              const term = i + 1;
              const isSelected = selectedTerms.includes(term);
              return (
                <button
                  key={i}
                  className={`relative py-2 px-3 border rounded text-gray-500 font-semibold hover:bg-green-100 transition-colors duration-75 ${
                    isSelected
                      ? 'bg-green-200' // Light green background when selected
                      : 'bg-gray-50'
                  }`}
                  onClick={() => {
                    if (templateName.trim() !== '') {
                      toggleYearSelection(term);
                    }
                  }}
                  disabled={!templateName.trim()}
                >
                  {/* Flex Container for Year Label and Checkmark */}
                  <div className="relative flex justify-between items-start">
                    {/* Left Side: Year Label and Major Tag */}
                    <div>
                      {/* Template Year Label */}
                      <span className="text-lg font-bold">{nMap[term]}</span>

                      {/* Major Tag Below the Year Label */}
                      <div className="text-left mt-0">
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                          Major: {selectedMajor}
                        </span>
                      </div>

                      {/* Fall 2024 Tag at the bottom left */}
                      <span className="absolute bottom-0 right-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800">
                        {yMap[term]}
                      </span>
                    </div>

                    {/* Right Side: Checkmark */}
                    <div className="flex items-start">
                      {isSelected && (
                        <div className="ml-2 w-4 h-4 bg-green-800 rounded-full flex items-center justify-center mt-1">
                          <FaCheck className="text-white" size={10} />
                        </div>
                      )}
                    </div>
                  </div>
                </button>
              );
            })}
          </div>

          {/* Add Button */}
          <div className="flex justify-end mt-3">
            <button
              onClick={() => {
                if (selectedTerms.length > 0 && templateName.trim() !== '') {
                  handleAutofillVariableCourses(
                    selectedTerms,
                    selectedMajor,
                    selectedStudents,
                    templateName
                  );
                  setIsTemplateModalVisible(false);
                  setIsSemesterSelectionVisible(false);
                  setSelectedMajor(null);
                  setSelectedTerms([]); // Reset selections
                }
              }}
              className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none ${
                selectedTerms.length === 0 || !templateName.trim()
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
              disabled={selectedTerms.length === 0 || !templateName.trim()}
            >
              Add Selected Years
            </button>
          </div>
        </>
      );
    }
  };

  const fieldLabels = {
    firstName: 'First Name',
    lastName: 'Last Name',
    classesTaken: 'Classes Taken',
    classesRegistered: 'Classes Registered',
    // Add more field mappings as needed
  };

  const camelCaseToWords = (str) => {
    if (str.toUpperCase() === str) return str;
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (s) => s.toUpperCase());
  };

  const handleSortClick = () => {
    onSort();
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setIsTemplateModalVisible(false);
      }
    };
    if (isTemplateModalVisible) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTemplateModalVisible]);

  const handleTemplateNameChange = (e) => {
    const value = e.target.value;
    setTemplateName(value);
    if (value.trim() === '') {
      setTemplateNameError(true);
    } else {
      setTemplateNameError(false);
    }
  };

  const handleResetFilters = () => {
    setSearchTerm('');
    setSelectedFilters({
      gradYear: [],
      program: [],
      advisors: [],
      performance: [],
    });
    setSelectedField('');
    setSelectedOperator('');
    setFilterValue('');
    setOperatorError('');
  };
  useEffect(() => {
    // Update selectedFilters whenever individual filter states change
    setSelectedFilters({
      gradYear: selectedGradYears,
      program: selectedPrograms,
      advisors: selectedAdvisors,
      performance: selectedPerformance,
    });
  }, [selectedGradYears, selectedPrograms, selectedAdvisors, selectedPerformance]);


  const getPossibleValuesForField = (field) => {
    const uniqueValues = new Set();
    students.forEach((student) => {
      if (student[field] !== undefined && student[field] !== null) {
        if (Array.isArray(student[field])) {
          student[field].forEach((value) => uniqueValues.add(value));
        } else {
          uniqueValues.add(student[field]);
        }
      }
    });
    return Array.from(uniqueValues);
  };

  return (
    <div className="p-4 bg-gray-50 shadow-md border rounded-lg">
      <h5 className="text-lg text-center font-bold mb-4">Filters</h5>

      {/* Search Filter */}
      <div className="mb-2">
        <label
          htmlFor="searchQuery"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Search:
        </label>
        <input
          type="text"
          id="searchQuery"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
        />
      </div>

      {/* Filter by Field */}
      <div className="mb-2">
        <label htmlFor="customField" className="block text-sm font-medium mb-1">
          Filter by Field:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={studentFields}
            value={selectedField ? [selectedField] : []}
            onChange={(e) => {
              const newValue = e.target.value[0] || '';
              setSelectedField(newValue);
              setSelectedOperator('');
              setFilterValue('');
              setOperatorError('');
            }}
            placeholder="Select a field"
            required={false}
            tagColor="gray"
            singleSelect={true}
          />
        </div>
      </div>

      {/* Operator Selection */}
      {selectedField && (
        <div className="mt-2">
          <label htmlFor="operator" className="block text-sm font-medium mb-1">
            Operator:
          </label>
          <div className="rounded-md">
            <MultiSelect
              suggestions={['equals', 'greater than', 'less than']}
              value={selectedOperator ? [selectedOperator] : []}
              onChange={(e) => {
                const operator = e.target.value[0] || '';
                setSelectedOperator(operator);
                // Check if operator is 'greater than' or 'less than' and field is not numeric
                if (
                  (operator === 'greater than' || operator === 'less than') &&
                  fieldTypes[selectedField] !== 'number'
                ) {
                  setOperatorError('Cannot use this operator with selected field');
                } else {
                  setOperatorError('');
                }
              }}
              placeholder="Select an operator"
              required={false}
              tagColor="gray"
              singleSelect={true}
            />
          </div>
          {operatorError && (
            <p className="text-red-500 text-xs mt-1">{operatorError}</p>
          )}
        </div>
      )}

      {/* Value Input */}
      {selectedOperator && !operatorError && (
        <div className="mt-2">
          <label htmlFor="filterValue" className="block text-sm font-medium mb-1">
            Value:
          </label>
          {fieldTypes[selectedField] === 'number' ? (
            <input
              type="number"
              id="filterValue"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              className="form-input mt-1 block w-full mb-2 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
            />
          ) : (
            <div className="rounded-md">
              <MultiSelect
                suggestions={getPossibleValuesForField(selectedField)}
                value={filterValue ? [filterValue] : []}
                onChange={(e) => setFilterValue(e.target.value[0] || '')}
                placeholder="Select a value"
                required={false}
                tagColor="gray"
                singleSelect={true}
              />
            </div>
          )}
        </div>
      )}

      {/* Graduation Year Filter */}
      <div className="mb-2">
        <label htmlFor="gradYear" className="block text-sm font-medium mb-1">
          Graduation Year:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={availableFilters.gradYear}
            value={selectedGradYears}
            onChange={(e) => setSelectedGradYears(e.target.value)}
            placeholder="Select graduation years"
            required={false}
            tagColor="red"
          />
        </div>
      </div>

      {/* Program Filter */}
      <div className="mb-2">
        <label htmlFor="program" className="block text-sm font-medium mb-1">
          Program:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={availableFilters.program}
            value={selectedPrograms}
            onChange={(e) => setSelectedPrograms(e.target.value)}
            placeholder="Select programs"
            required={false}
            tagColor="green"
          />
        </div>
      </div>

      {/* Performance Filter */}
      <div className="mb-2">
        <label htmlFor="performance" className="block text-sm font-medium mb-1">
          Performance:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={availableFilters.performance}
            value={selectedPerformance}
            onChange={(e) => setSelectedPerformance(e.target.value)}
            placeholder="Select performance levels"
            required={false}
            tagColor="orange"
          />
        </div>
      </div>

      {/* Advisor Filter */}
      <div className="mb-2">
        <label htmlFor="advisors" className="block text-sm font-medium mb-1">
          Advisor:
        </label>
        <div className="rounded-md">
          <MultiSelect
            suggestions={availableFilters.advisors}
            value={selectedAdvisors}
            onChange={(e) => setSelectedAdvisors(e.target.value)}
            placeholder="Select advisors"
            required={false}
            tagColor="purple"
          />
        </div>
      </div>
        <button
          onClick={toggleTemplateModalVisibility}
          className="w-full px-4 py-2 rounded-md flex items-center space-x-2 justify-center bg-blue-500 hover:bg-blue-700 text-white"
        >
          <FaPlus size={14} className="text-sm" /> {/* Corrected size prop */}
          <span className="text-sm">Templates</span>
        </button>

        <button
          className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-2"
          onClick={handleSelectAllClick}
        >
          {everythingSelected ? 'Unselect All Students' : 'Select All Students'}
        </button>


      {/* Reset Filters Button */}
      <button
        className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-2"
        onClick={handleResetFilters}
      >
        Reset Filters
      </button>

      {/* Students Display Count */}
      <div className="flex justify-center mt-3">
        <span className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          {filteredStudentsCount} Students Displayed
        </span>
      </div>

      <Modal isVisible={isTemplateModalVisible} onClose={() => {setIsTemplateModalVisible(false); setIsSemesterSelectionVisible(false);}}>
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className={`flex flex-col bg-white rounded-md border border-gray-500 max-w-3xl w-full ${isSemesterSelectionVisible ? 'h-[81vh]' : 'h-[70vh]'}`}>
            {/* Modal Header */}
            <div className="flex flex-col p-3 md:p-5 border-b dark:border-gray-600">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-500 dark:text-white">
                  Select a Template
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => {setIsTemplateModalVisible(false); setIsSemesterSelectionVisible(false);}}
                >
                  {/* Close button SVG */}
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>

              {/* Search Bar */}
              {!isSemesterSelectionVisible && (
                <div className="mt-3">
                  <input
                    type="text"
                    placeholder="Search templates..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
              )}

              {/* Tags */}
              <div className="flex space-x-2 mt-3">
                {selectedMajor && (
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                    Major: {selectedMajor}
                  </span>
                )}
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  Students Selected: {selectedStudents.length}
                </span>
              </div>
            </div>
            
            {/* Modal Body */}
            <div className="p-4 md:p-5 flex-1 overflow-y-auto">
              {renderModalContent()}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ResultsComponent = ({ students, adjustment, selectedResource, onClose }) => {
  const [filteredStudents, setFilteredStudents] = useState(students);
  const [filters, setFilters] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState(null);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // Exclude certain fields
  const excludeFields = ['id', 'fromFirestore', 'surveyAnswers', 'name', 'email'];

  // Memoize fieldTypes to prevent unnecessary re-renders
  const fieldTypes = useMemo(() => {
    const types = {};
    if (students.length > 0) {
      const student = students[0];
      Object.keys(student).forEach((field) => {
        if (!excludeFields.includes(field)) {
          const value = student[field];
          if (Array.isArray(value)) {
            // For arrays, determine the type of the first element
            if (value.length > 0) {
              types[field] = `array-${typeof value[0]}`;
            } else {
              types[field] = 'array';
            }
          } else {
            types[field] = typeof value;
          }
        }
      });
    }
    return types;
  }, [students]);

  // Fetch selectedStudents from Firestore
  useEffect(() => {
    const fetchSelectedStudents = async () => {
      if (currentUser) {
        const userRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setSelectedStudents(data.selectedStudents || []);
        }
      }
    };
    fetchSelectedStudents();
  }, [currentUser]);

  const handleCheckboxChange = useCallback(
    async (studentId, isChecked) => {
      try {
        const userRef = doc(db, 'users', currentUser.uid);

        if (isChecked) {
          await updateDoc(userRef, {
            selectedStudents: arrayUnion(studentId),
          });
          setSelectedStudents((prev) => [...prev, studentId]);
        } else {
          await updateDoc(userRef, {
            selectedStudents: arrayRemove(studentId),
          });
          setSelectedStudents((prev) => prev.filter((id) => id !== studentId));
        }
      } catch (error) {
        console.error('Error updating selectedStudents array:', error);
        toast.error('Failed to update selection. Please try again.');
      }
    },
    [currentUser]
  );

  const handleSelectAll = useCallback(async () => {
    try {
      const userRef = doc(db, 'users', currentUser.uid);
  
      if (filteredStudents.every(student => selectedStudents.includes(student.id))) {
        // Unselect filtered students
        const updatedSelectedStudents = selectedStudents.filter(
          id => !filteredStudents.some(student => student.id === id)
        );

        await updateDoc(userRef, {
            selectedStudents: updatedSelectedStudents,
        });
        setSelectedStudents(updatedSelectedStudents);
      } else {
        // Select all students
        const studentIdsToSelect = filteredStudents.map((student) => student.id);
  
        await updateDoc(userRef, {
          selectedStudents: studentIdsToSelect,
        });
        setSelectedStudents(studentIdsToSelect);
      }
    } catch (error) {
      console.error('Error selecting all students:', error);
      toast.error('Failed to select all students. Please try again.');
    }
  }, [currentUser, filteredStudents, selectedStudents]);
  

  const handleSearch = useCallback((term) => {
    setSearchTerm(term);
  }, []);

  const handleFilter = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const handleSort = useCallback(() => {
    setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  }, []);

  const handleRecommendResource = useCallback(
    async (studentId) => {
      try {
        const studentRef = doc(db, 'users', studentId);
        await updateDoc(studentRef, {
          Resources: arrayUnion(selectedResource.id),
        });
        // Update local state to reflect the change
        setFilteredStudents((prevStudents) =>
          prevStudents.map((student) =>
            student.id === studentId
              ? {
                  ...student,
                  resources: [...(student.resources || []), selectedResource.id],
                }
              : student
          )
        );
      } catch (error) {
        console.error('Error recommending resource:', error);
        toast.error('Failed to recommend resource. Please try again.');
      }
    },
    [selectedResource]
  );

  // Apply filters and search term
  useEffect(() => {
    let filtered = students;

    // Apply search term
    if (searchTerm) {
      filtered = filtered.filter((student) =>
        student.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply other filters
    if (filters.gradYear && filters.gradYear.length > 0) {
      filtered = filtered.filter((student) =>
        filters.gradYear.includes(student.surveyAnswers?.SchoolEnd?.year)
      );
    }

    if (filters.program && filters.program.length > 0) {
      filtered = filtered.filter((student) =>
        Array.isArray(student.major)
          ? student.major.some((program) => filters.program.includes(program))
          : filters.program.includes(student.major)
      );
    }

    if (filters.advisors && filters.advisors.length > 0) {
      filtered = filtered.filter((student) =>
        filters.advisors.includes(student.advisor)
      );
    }

    if (filters.performance && filters.performance.length > 0) {
      filtered = filtered.filter((student) =>
        filters.performance.includes(student.performanceLevel)
      );
    }

    // Apply custom filter
    if (
      filters.customFilter &&
      !filters.customFilter.error &&
      filters.customFilter.field &&
      filters.customFilter.operator &&
      filters.customFilter.value
    ) {
      const { field, operator, value } = filters.customFilter;
      const fieldType = fieldTypes[field];

      filtered = filtered.filter((student) => {
        const studentValue = student[field];
        if (studentValue === undefined || studentValue === null) {
          return false;
        }

        if (operator === 'equals') {
          if (Array.isArray(studentValue)) {
            // Handle array values
            return studentValue.some(
              (val) =>
                String(val).trim().toLowerCase() === value.trim().toLowerCase()
            );
          } else {
            return (
              String(studentValue).trim().toLowerCase() ===
              value.trim().toLowerCase()
            );
          }
        } else if (operator === 'greater than') {
          if (fieldType === 'number') {
            return parseFloat(studentValue) > parseFloat(value);
          } else if (fieldType.startsWith('array-')) {
            // Handle arrays of numbers
            return studentValue.some(
              (val) => parseFloat(val) > parseFloat(value)
            );
          } else {
            return false; // Not a number, cannot compare
          }
        } else if (operator === 'less than') {
          if (fieldType === 'number') {
            return parseFloat(studentValue) < parseFloat(value);
          } else if (fieldType.startsWith('array-')) {
            // Handle arrays of numbers
            return studentValue.some(
              (val) => parseFloat(val) < parseFloat(value)
            );
          } else {
            return false; // Not a number, cannot compare
          }
        }
        return true; // If operator is unknown, include the student
      });
    }

    // Apply sorting
    if (sortOrder) {
      filtered.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if (nameA < nameB) return sortOrder === 'asc' ? -1 : 1;
        if (nameA > nameB) return sortOrder === 'asc' ? 1 : -1;
        return 0;
      });
    }

    setFilteredStudents(filtered);
  }, [students, searchTerm, filters, sortOrder, fieldTypes]);

  const everythingSelected = filteredStudents.every(student => selectedStudents.includes(student.id));

  return (
    <div className="flex w-full">
      
  {/* Filters Sidebar */}
  {adjustment !== 'Resources' && (
    <div className="w-1/3 mt-6">
      <SearchComponent
        students={students}
        fieldTypes={fieldTypes}
        onSearch={handleSearch}
        onFilter={handleFilter}
        selectedStudents={selectedStudents}
        onSelectAll={handleSelectAll} 
        onSort={handleSort}
        filteredStudentsCount={filteredStudents.length}
        everythingSelected={everythingSelected}
      />
    </div>
  )}

  {/* Students Display */}
  <div
    className={`${
      adjustment !== 'Resources' ? 'ml-4 w-3/4' : 'w-full'
    } w-full mt-6`}
  >
    <div className="p-4 bg-gray-50 shadow-md border rounded-lg h-auto min-h-[60vh] md:min-h-[70vh] lg:min-h-[80vh]">
      {/* Header with Centered Title */}
      <div className="items-center justify-center mb-4 relative">
        {adjustment === 'Resources' ? (
          <>
          <h5 className="text-lg font-bold text-center">Select a Student</h5>
          <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={onClose}
            >
              <FaTimes size={20} />
            </button>
            </>
        ) : (
          <h5 className="text-lg font-bold text-center">Students</h5>
        )}
      </div>

      {/* Conditionally Render Headers Based on 'adjustment' */}
      <div className="grid grid-cols-12 font-semibold text-sm text-gray-500 mb-2">
        {adjustment !== 'Resources' && (
          <div className="col-span-1 ml-3 text-left">
            Select{' '}
            <FontAwesomeIcon
              icon={faSort}
              className="mb-0.5 ml-0.5"
              style={{ fontSize: '10px' }}
            />
          </div>
        )}

        {/* Checkbox Header */}
        {adjustment !== 'Resources' && (
          <div className="text-center col-span-1"></div>
        )}

        {/* Name */}
        <div
          className={`${
            adjustment !== 'Resources' ? 'col-span-3' : 'col-span-4'
          } text-left mr-8`}
        >
          Name{' '}
          <FontAwesomeIcon
            icon={faSort}
            className="mb-0.5 ml-0.5"
            style={{ fontSize: '10px' }}
          />
        </div>

        {/* Major/Program */}
        <div className="text-center col-span-3">
          Major/Program{' '}
          <FontAwesomeIcon
            icon={faSort}
            className="mb-0.5 ml-0.5"
            style={{ fontSize: '10px' }}
          />
        </div>

        {/* Graduation Year */}
        <div className="text-center col-span-2 mr-2">
          Grad Year{' '}
          <FontAwesomeIcon
            icon={faSort}
            className="mb-0.5 ml-0.5"
            style={{ fontSize: '10px' }}
          />
        </div>

        {/* Action */}
        <div className="text-center col-span-2 ml-10">
          Action{' '}
          <FontAwesomeIcon
            icon={faSort}
            className="mb-0.5 ml-0.5"
            style={{ fontSize: '10px' }}
          />
        </div>
      </div>

      {/* Students List */}
      <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 300px)' }}>
        <div>
          {filteredStudents.map((student) => (
            <StudentItem
              key={student.id}
              student={student}
              isSelected={selectedStudents.includes(student.id)}
              onCheckboxChange={handleCheckboxChange}
              adjustment={adjustment}
              selectedResource={selectedResource}
              handleRecommendResource={handleRecommendResource}
            />
          ))}
        </div>
      </div>
    </div>
  </div>
</div>
  );
};

export default ResultsComponent;