import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/database';

// Initialize Firebase with environment variables
const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
});

console.log('Project:', process.env.REACT_APP_PROJECT_ID);

// Initialize services
const auth = app.auth();
const db = app.firestore(); // This points to the default database
const functions = app.functions();
const database = app.database();

// Set the authentication persistence to LOCAL
auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    console.log('Auth persistence set to LOCAL');
  })
  .catch((error) => {
    console.error('Error setting auth persistence:', error);
  });

// Function to make authenticated REST API requests to the named database and process data
async function coursesdataDB(path, options = {}) {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User is not authenticated');
    }

    const idToken = await user.getIdToken();

    const projectId = process.env.REACT_APP_PROJECT_ID;
    const databaseId = 'coursesdata'; // Your named database ID

    const url = `https://firestore.googleapis.com/v1/projects/${projectId}/databases/${databaseId}/documents/${path}`;

    const response = await fetch(url, {
      method: options.method || 'GET',
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json',
      },
      body: options.body ? JSON.stringify(options.body) : undefined,
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error during request: ${response.status} ${errorText}`);
    }

    const data = await response.json();

    // Process and return the data
    if (data.documents) {
      // If fetching a collection
      const processedData = data.documents.map((doc) => {
        const fields = doc.fields;
        return {
          id: doc.name.split('/').pop(),
          ...parseFirestoreFields(fields),
        };
      });
      return processedData;
    } else if (data.fields) {
      // If fetching a single document
      const processedData = {
        id: data.name.split('/').pop(),
        ...parseFirestoreFields(data.fields),
      };
      return processedData;
    } else {
      // No data found
      return null;
    }
  } catch (error) {
    console.error('Error during named database request:', error);
    throw error;
  }
}

// Helper functions to parse Firestore fields (used internally)
function parseFirestoreFields(fields) {
  const parsedData = {};
  for (const [key, value] of Object.entries(fields)) {
    parsedData[key] = parseFirestoreValue(value);
  }
  return parsedData;
}

function parseFirestoreValue(value) {
  if (value.stringValue !== undefined) {
    return value.stringValue;
  } else if (value.integerValue !== undefined) {
    return parseInt(value.integerValue, 10);
  } else if (value.doubleValue !== undefined) {
    return value.doubleValue;
  } else if (value.booleanValue !== undefined) {
    return value.booleanValue;
  } else if (value.arrayValue !== undefined) {
    return value.arrayValue.values
      ? value.arrayValue.values.map(parseFirestoreValue)
      : [];
  } else if (value.mapValue !== undefined) {
    return parseFirestoreFields(value.mapValue.fields);
  } else if (value.nullValue !== undefined) {
    return null;
  } else if (value.timestampValue !== undefined) {
    return value.timestampValue;
  } else {
    // Add more types as needed
    console.warn('Unknown Firestore value type:', value);
    return null;
  }
}

export {
  auth,
  db,
  database,
  functions,
  firebase,
  coursesdataDB,
};
export default app;