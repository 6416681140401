import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import MultiSelect from '../../ClassSearch/MultiSelect';

const FilterCard = ({
  searchQuery,
  setSearchQuery,
  levelFilter,
  setLevelFilter,
  majorFilter,
  setMajorFilter,
  creditsFilter,
  setCreditsFilter,
  handleResetFilters,
  displayedCourseCount,
}) => {
  const { fetchedCourseData } = useAuth();

  // Extract unique filter options using useMemo for performance
  const uniqueLevels = useMemo(() => {
    if (!fetchedCourseData) return [];
    const levels = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.courseYear) levels.add(item.courseYear);
    });
    return Array.from(levels).sort((a, b) => parseInt(a) - parseInt(b));
  }, [fetchedCourseData]);

  const uniqueMajors = useMemo(() => {
    if (!fetchedCourseData) return [];
    const majors = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Major) {
        item.Major.split(',').forEach((major) => {
          majors.add(major.trim());
        });
      }
    });
    return Array.from(majors).sort();
  }, [fetchedCourseData]);

  const uniqueCredits = useMemo(() => {
    if (!fetchedCourseData) return [];
    const credits = new Set();
    Object.values(fetchedCourseData).forEach((item) => {
      if (item.Credits) credits.add(item.Credits);
    });
    return Array.from(credits).sort((a, b) => parseFloat(a) - parseFloat(b));
  }, [fetchedCourseData]);

  // Define suggestions for MultiSelect components
  const levelSuggestions = useMemo(
    () => uniqueLevels.map((level) => `${level}`),
    [uniqueLevels]
  );

  const majorSuggestions = useMemo(
    () => uniqueMajors,
    [uniqueMajors]
  );

  const creditsSuggestions = useMemo(
    () => uniqueCredits.map((credit) => `${credit} Credit(s)`),
    [uniqueCredits]
  );

  // Handle Reset Filters with feedback
  const handleReset = () => {
    handleResetFilters();
  };

  return (
    <div className="p-4 bg-gray-50 shadow-md border rounded-lg">
      <h5 className="text-lg text-center font-bold mb-4">Filters</h5>

      {/* Search Filter */}
      <div className="mb-2">
        <label
          htmlFor="searchQuery"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Search:
        </label>
        <input
          type="text"
          id="searchQuery"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="form-input mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          placeholder=""
        />
      </div>

      {/* Level Filter */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Level:
        </label>
        <MultiSelect
          suggestions={levelSuggestions}
          value={levelFilter}
          onChange={(selected) => setLevelFilter(selected.target.value)}
          placeholder="Select Level(s)"
          required={false}
          unselectable={[]} // Add any unselectable items if needed
          tagColor="blue"
        />
      </div>

      {/* Major Filter */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Major:
        </label>
        <MultiSelect
          suggestions={majorSuggestions}
          value={majorFilter}
          onChange={(selected) => setMajorFilter(selected.target.value)}
          placeholder="Select Major(s)"
          required={false}
          unselectable={[]} // Add any unselectable items if needed
          tagColor="green"
        />
      </div>

      {/* Credits Filter */}
      <div className="mb-2">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Credits:
        </label>
        <MultiSelect
          suggestions={creditsSuggestions}
          value={creditsFilter}
          onChange={(selected) => setCreditsFilter(selected.target.value)}
          placeholder="Select Credit(s)"
          required={false}
          unselectable={[]} // Add any unselectable items if needed
          tagColor="purple"
        />
      </div>

      <button
        className="w-full bg-blue-500 hover:bg-blue-700 text-white text-sm py-2 px-4 rounded mt-1"
        onClick={handleReset}
      >
        Reset Filters
      </button>

      {/* Courses Display Count */}
      <div className="flex justify-center mt-3">
        <span className="bg-gray-200 text-gray-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          {displayedCourseCount} Courses Displayed
        </span>
      </div>
    </div>
  );
};

export default FilterCard;
