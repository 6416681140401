import React, { useState, useEffect } from 'react';
import DemandGraph from './DemandGraph';
import Instructors from './Instructors';
import PopularPrograms from './PopularPrograms';
import StudentDistribution from './StudentDistribution';
import GradeDistribution from './GradeDistribution';

const AnalyticsClassSearch = ({ classData }) => {
  const [isHovered, setIsHovered] = useState(false); // State for hover effect
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch('/courseData.json');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setCourses(data.courses);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };
    fetchCourses();
  }, []);

  const handleButtonClick = (event) => {
    event.stopPropagation(); // Prevent the click from toggling collapse
    setIsModalOpen(true); // Open modal on click
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal
  };

  const classDataForModal = courses[0]

  return (
    <div className="border bg-white rounded-lg p-1.5 mb-2">
      <div className="grid grid-cols-12 items-center">
        <div className="text-center font-bold col-span-2">{classData.courseInfo_courseNumber}</div>
        <div className="text-center col-span-5">{classData.courseInfo_courseName}</div>
        <div className="text-center col-span-2">
          <div className="text-center font-semibold text-sm">
            {classData.SectionsOffered} sections
          </div>
          <div className="text-center text-xs">
            {classData.TotalSeatsOffered} seats
          </div>
        </div>
        <div className="mr-2 text-center col-span-2">
          <div className="text-center font-semibold text-sm">
            {classData.SectionsNeeded} sections
          </div>
          <div className="text-center text-xs">
            {classData.TotalSeatsNeeded} seats
          </div>
        </div>
        <div className="text-right col-span-1 mr-1">
          <button
            onClick={handleButtonClick}
            className={`text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:outline-none font-medium rounded text-xs px-2 py-1 transition-all duration-300`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Details
          </button>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center h-screen justify-center bg-black bg-opacity-50 backdrop-blur-sm" onClick={handleCloseModal}>
         <div className="rounded-lg bg-gray-50 p-2">
            <div className="text-right ml-10">
              <button onClick={handleCloseModal} className="text-gray-500 hover:text-gray-800">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            {/* Demand Graph */}
            <div className="mt-0 w-full h-full px-4">
              <DemandGraph course={classDataForModal} />
            </div>

            {/* Horizontal layout for the 4 components */}
            <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-4 mt-3.5 px-4 mb-2.5">
              <StudentDistribution course={classDataForModal} />
              <Instructors course={classDataForModal} />
              <PopularPrograms course={classDataForModal} />
              <GradeDistribution course={classDataForModal} />
            </div>
          </div>
        </div> 
      )}
    </div>
  );
};

export default AnalyticsClassSearch;
