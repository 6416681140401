// src/components/Advisor/TimeRangePicker.jsx

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const TimeRangePicker = ({ startTime, endTime, onChange }) => {
  const [availableEndTimes, setAvailableEndTimes] = useState([]);

  const generateTimeOptions = () => {
    const times = [];
    const start = 9 * 60; // 9:00 AM in minutes
    const end = 17 * 60; // 5:00 PM in minutes

    for (let minutes = start; minutes <= end; minutes += 15) {
      const hr = Math.floor(minutes / 60);
      const min = minutes % 60;
      const ampm = hr >= 12 ? 'PM' : 'AM';
      const displayHr = hr % 12 === 0 ? 12 : hr % 12;
      const formattedTime = `${displayHr}:${min.toString().padStart(2, '0')} ${ampm}`;
      const value = `${hr.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}`;

      times.push({ label: formattedTime, value });
    }

    return times;
  };

  const timeOptions = generateTimeOptions();

  useEffect(() => {
    if (startTime) {
      const filteredEndTimes = timeOptions.filter(
        (time) => time.value > startTime
      );
      setAvailableEndTimes(filteredEndTimes);

      // If the current endTime is not in the new availableEndTimes, reset it
      if (endTime && !filteredEndTimes.some(time => time.value === endTime)) {
        onChange({ start: startTime, end: '' });
      } else {
        onChange({ start: startTime, end: endTime });
      }
    } else {
      setAvailableEndTimes([]);
    }
  }, [startTime, endTime, timeOptions, onChange]);

  const handleStartChange = (e) => {
    const newStartTime = e.target.value;
    const filteredEndTimes = timeOptions.filter(
      (time) => time.value > newStartTime
    );
    setAvailableEndTimes(filteredEndTimes);
    // Reset endTime if it's no longer valid
    if (!filteredEndTimes.some(time => time.value === endTime)) {
      onChange({ start: newStartTime, end: '' });
    } else {
      onChange({ start: newStartTime, end: endTime });
    }
  };

  const handleEndChange = (e) => {
    const newEndTime = e.target.value;
    onChange({ start: startTime, end: newEndTime });
  };

  return (
    <div className="flex space-x-4">
      <div>
        <label htmlFor="startTime" className="block text-sm font-medium text-gray-700 dark:text-white">
          Start Time
        </label>
        <select
          id="startTime"
          value={startTime}
          onChange={handleStartChange}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          required
        >
          <option value="">Select Start Time</option>
          {timeOptions.map((time) => (
            <option key={time.value} value={time.value}>
              {time.label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label htmlFor="endTime" className="block text-sm font-medium text-gray-700 dark:text-white">
          End Time
        </label>
        <select
          id="endTime"
          value={endTime}
          onChange={handleEndChange}
          className={`mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${
            startTime && availableEndTimes.length === 0 ? 'border-red-500' : ''
          }`}
          required
          disabled={!startTime}
        >
          <option value="">Select End Time</option>
          {availableEndTimes.map((time) => (
            <option key={time.value} value={time.value}>
              {time.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

TimeRangePicker.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TimeRangePicker;