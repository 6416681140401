import React, { useState, useEffect, useRef } from 'react';
import { TextInput } from 'flowbite-react';
import { toast } from 'react-toastify';

const MultiSelect = ({
  suggestions,
  value = [],
  onChange,
  placeholder,
  required,
  unselectable = [],
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedItems, setSelectedItems] = useState(value || []);
  const [warning, setWarning] = useState(false);
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        autocompleteRef.current &&
        !autocompleteRef.current.contains(event.target)
      ) {
        if (required && selectedItems.length === 0) {
          setWarning(true);
        } else {
          setWarning(false);
        }
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedItems, required]);

  const handleChange = (e) => {
    const userInput = e.target.value;
    setInputValue(userInput);

    const filtered = suggestions
      .filter((suggestion) => typeof suggestion === 'string')
      .filter((suggestion) =>
        suggestion.toLowerCase().includes(userInput.toLowerCase())
      );

    setFilteredSuggestions(filtered);
    setShowSuggestions(true);
    setWarning(false); // Reset warning when user starts typing again
  };

  const handleCheckboxChange = (suggestion) => {
    if (unselectable.includes(suggestion)) {
      toast.warning("You can't select the same minor as major");
      return; // Prevent selection
    }

    setSelectedItems((prevItems) => {
      const isSelected = prevItems.includes(suggestion);
      const updatedItems = isSelected
        ? prevItems.filter((item) => item !== suggestion)
        : [...prevItems, suggestion];

      // Check if the number of selected items exceeds 4
      if (updatedItems.length > 4) {
        toast.warning('You can only select up to 4 Options');
        return prevItems; // Return previous items without updating
      }

      onChange(updatedItems); // Pass the selected items directly
      setInputValue(''); // Clear input value after selection
      return updatedItems;
    });
  };

  const handleTagRemove = (item) => {
    setSelectedItems((prevItems) => {
      const updatedItems = prevItems.filter(
        (selectedItem) => selectedItem !== item
      );
      onChange(updatedItems);
      return updatedItems;
    });
  };

  const handleInputFocus = () => {
    setFilteredSuggestions(suggestions); // Show all suggestions on focus
    setShowSuggestions(true);
  };

  return (
    <div className="relative" ref={autocompleteRef}>
      <TextInput
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChange}
        onFocus={handleInputFocus}
        className="w-full"
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="absolute bg-white border border-gray-300 rounded w-full mt-1 max-h-40 overflow-y-auto z-10">
          {filteredSuggestions.map((suggestion, index) => (
            <li key={index}>
              <div
                className="flex items-center p-2 rounded hover:bg-gray-100 cursor-pointer"
                onClick={() => handleCheckboxChange(suggestion)}
              >
                <input
                  type="checkbox"
                  checked={selectedItems.includes(suggestion)}
                  onChange={() => handleCheckboxChange(suggestion)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                  onClick={(e) => e.stopPropagation()} // Prevent the checkbox click from bubbling up
                />
                <label className="w-full ms-2 text-sm font-medium text-gray-900">
                  {suggestion}
                </label>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="mt-2 flex flex-wrap">
        {selectedItems.map((item, index) => (
          <span
            key={index}
            className="inline-flex items-center px-2 py-1 mr-2 text-sm font-medium text-blue-800 bg-blue-100 rounded dark:bg-blue-900 dark:text-blue-300"
          >
            {item}
            <button
              type="button"
              className="inline-flex items-center p-1 ms-2 text-sm text-blue-400 bg-transparent rounded-sm hover:bg-blue-200 hover:text-blue-900 dark:hover:bg-blue-800 dark:hover:text-blue-300"
              aria-label="Remove"
              onClick={() => handleTagRemove(item)}
            >
              <svg
                className="w-2 h-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Remove badge</span>
            </button>
          </span>
        ))}
      </div>
      {/* Warning Message */}
      {warning && required && (
        <div className="text-red-500 mt-2">
          Please select at least one option.
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
