import React, { useEffect, useRef } from 'react';
import { FaPlus } from 'react-icons/fa';

const PrerequisiteDisplay = ({
  prerequisites,
  position,
  selectedCourse,
  semester,
  yearNumber,
  onAddPrerequisiteCourse,
  onClose, // New prop for handling close action
}) => {
  const ref = useRef(null);

  // Move the Hook to the top level, before any returns
  useEffect(() => {
    // If position is not set, do not add the event listener
    if (!position) return;

    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose(); // Call the onClose prop to update the parent state
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, position]); // Include position in dependencies

  // Now you can safely return null if position is not provided
  if (!position) return null;

  const dynamicStyles = {
    position: 'fixed',
    top: position.top - 10,
    left: position.left + position.width / 2,
    transform: 'translate(-50%, -100%)',
    zIndex: 50,
  };

  const handleAddPrereqClick = (event, courseNumber) => {
    event.preventDefault();
    event.stopPropagation();
    console.log('handleAddPrereqClick called');
    console.log('courseNumber:', courseNumber);

    let targetYear = yearNumber;
    let targetSemester = semester;

    // Determine the previous semester
    if (targetSemester === 'Spring') {
      targetSemester = 'Fall';
    } else {
      targetSemester = 'Spring';
      targetYear -= 1;
    }

    if (targetYear < 1) {
      alert('Cannot add course before first year');
      return;
    }

    onAddPrerequisiteCourse(courseNumber, targetYear, targetSemester);
  };

  if (selectedCourse.isPrereqMet) {
    return (
      <div
        style={{
          ...dynamicStyles,
          boxShadow: '0 0 30px rgba(0, 0, 0, 0.1)', // Adjusted shadow for a more subtle effect
          width: '18%', // Reduced width
          margin: '20px auto 0', // Adds space at the top to move it lower
        }}
        className="prerequisite-display bg-gray-100 p-2 border border-gray-200 rounded-lg shadow-lg"
      >
        <h3 className="text-sm font-semibold mb-1 text-center">
          Warning: {selectedCourse.courseInfo_courseNumber} is typically not offered in the {semester}.
        </h3>
      </div>
    );    
  }

  return (
    <div
      ref={ref}
      style={{
        ...dynamicStyles,
        boxShadow: '0 0 50px rgba(0, 0, 0, 0.1)',
      }}
      className="prerequisite-display bg-gray-100 p-2 border border-gray-200 rounded-lg shadow-xxl"
    >
      <h3 className="text-sm font-semibold mb-2 text-center">
        Warning: Requisites for {selectedCourse.courseInfo_courseNumber} are not met.
      </h3>
      {prerequisites.map((prerequisiteGroup, groupIndex) => (
        <React.Fragment key={groupIndex}>
          <div className="flex flex-row items-center justify-center gap-2">
            {prerequisiteGroup.map((prereq, index) => (
              <React.Fragment key={index}>
                <div className="relative bg-red-50 border border-red-200 rounded-md p-1.5 shadow-sm flex flex-col items-start text-xs">
                  <div className="flex justify-between w-full mb-1">
                    <span className="font-bold text-xs text-gray-700 mt-0.5">{prereq}</span>
                    <span className="bg-red-200 text-gray-700 font-semibold text-xxs ml-1 px-1 py-0.5 rounded">
                      C-
                    </span>
                  </div>
                  <span className="text-xxs text-gray-600">Prerequisite</span>
                  {/* Plus Icon to Add Course */}
                  <button
                    className="absolute bottom-1 right-1 text-gray-500 hover:text-black prerequisite-add-button"
                    onClick={(event) => handleAddPrereqClick(event, prereq)}
                  >
                    <FaPlus />
                  </button>
                </div>
                {index < prerequisiteGroup.length - 1 && (
                  <span className="text-xs font-semibold text-gray-500 mx-1">OR</span>
                )}
              </React.Fragment>
            ))}
          </div>
          {groupIndex < prerequisites.length - 1 && (
            <div className="flex justify-center my-2">
              <span className="text-xs font-semibold text-gray-500">AND</span>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default PrerequisiteDisplay;
