import React, { useEffect, useState } from 'react';

const StudentDistribution = ({ course }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (course) {
      setData(course.studentDistribution);
    }
  }, [course]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const maxCount = data.yearDistribution.length > 0 ? Math.max(...data.yearDistribution.map(item => item.count)) : 1;

  return (
    <div className="max-w-sm w-full bg-white rounded-lg shadow dark:bg-gray-800 p-3 md:p-6">
      <div className="flex justify-between items-start mb-2">
        <div>
          <h5 className="text-sm font-medium text-gray-500 dark:text-gray-400">STUDENT DISTRIBUTION</h5>
          <h3 className="text-3xl font-bold text-gray-900 dark:text-white">{data.totalStudents}</h3>
          <p className="text-sm font-medium text-gray-500 dark:text-gray-400">Students</p>
        </div>
      </div>
      <div>
        {data.yearDistribution.map((item, index) => (
          <div key={index} className="flex items-center mb-2">
            <div className="flex-1">
              <div className="text-sm font-medium text-gray-500 dark:text-gray-400">{item.year}</div>
              <div className="w-full h-2 bg-gray-200 dark:bg-gray-700 rounded-full mt-1">
                <div
                  className={`h-full rounded-full ${item.count === maxCount ? 'bg-blue-600' : 'bg-gray-400'}`}
                  style={{ width: `${(item.count / data.totalStudents) * 100}%` }}
                ></div>
              </div>
            </div>
            <div className="ml-2 text-sm font-medium text-gray-900 dark:text-white">{item.count}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StudentDistribution;
