import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

const Signup = () => {
  const { signup, logout, fetchUserData, setUserRole } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const role = location.pathname.split('/').pop(); // Assumes role is the last segment in the path

  const validateFields = () => {
    let errors = {};

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      errors.passwordConfirm = 'Passwords do not match';
    }

    if (!emailRef.current.value) {
      errors.email = 'Email is required';
    }

    if (!passwordRef.current.value) {
      errors.password = 'Password is required';
    }

    if (!passwordConfirmRef.current.value) {
      errors.passwordConfirm = 'Please confirm your password';
    }

    if (!firstNameRef.current.value) {
      errors.firstName = 'First name is required';
    }

    if (!lastNameRef.current.value) {
      errors.lastName = 'Last name is required';
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

async function handleSubmit(e) {
  e.preventDefault();
  console.log('Signup form submitted');

  if (!validateFields()) {
    console.log('Validation failed');
    return;
  }

  try {
    setError('');
    setLoading(true);
    console.log('Starting signup process');

    // Log out existing user before signing up a new one
    if (logout && typeof logout === 'function') {
      await logout();
      console.log('Logged out existing user');
    }

    const { user } = await signup(emailRef.current.value, passwordRef.current.value);
    console.log('User signed up:', user);

    if (!user || !user.uid) {
      throw new Error('No valid user object or UID returned from signup.');
    }

    // Call cloud function to set custom claims and create user document
    const setCustomClaimsFn = httpsCallable(functions, 'setCustomClaims');
    const claimsResult = await setCustomClaimsFn({
      email: emailRef.current.value,
      role,
      firstName: firstNameRef.current.value,
      lastName: lastNameRef.current.value
    });
    console.log('Custom claims set successfully:', claimsResult.data);

    // Force refresh the token to ensure custom claims are applied
    await user.getIdToken(true);
    console.log('User ID token refreshed');

    // Fetch user data with retry mechanism
    let fetchedUser;
    const maxRetries = 5;
    let retries = 0;
    const retryDelay = 2000; // 2 seconds

    while (retries < maxRetries) {
      try {
        fetchedUser = await fetchUserData(user);
        if (fetchedUser) break;
      } catch (err) {
        console.warn(`Attempt ${retries + 1} to fetch user data failed:`, err);
        await new Promise(resolve => setTimeout(resolve, retryDelay));
        retries++;
      }
    }

    if (!fetchedUser) {
      throw new Error('Failed to fetch user data after multiple attempts.');
    }

    setUserRole(role); // Update the user role in the context

    console.log('User data fetched successfully:', fetchedUser);

    // Navigate based on role
    navigateBasedOnRole(role);
  } catch (err) {
    console.error('Error during signup:', err);
    const newFieldErrors = {};

    if (err.code === 'auth/email-already-in-use') {
      newFieldErrors.email = 'The email address is already in use by another account.';
    } else if (err.code === 'auth/weak-password') {
      newFieldErrors.password = 'Password should be at least 6 characters.';
    } else if (err.code === 'not-found') { // Handle 'not-found' error
      newFieldErrors.general = 'User data not found. Please try again later.';
    } else if (err.code === 'internal') {
      newFieldErrors.general = 'An internal error occurred. Please try again later.';
    } else if (err.message) {
      setError(err.message);
    } else {
      setError('Failed to create an account');
    }

    setFieldErrors(prevErrors => ({ ...prevErrors, ...newFieldErrors }));
    setLoading(false);
  }
}


  function navigateBasedOnRole(userRole) {
    console.log(`Navigating based on role: ${userRole}`);
    switch (userRole) {
      case 'student':
        navigate('/Survey');
        break;
      case 'advisor':
        navigate('/AdminDashboard');
        break;
      case 'admin':
      case 'super_admin':
        navigate('/AdminDashboard');
        break;
      default:
        navigate('/Survey');
        break;
    }
    setLoading(false);
    console.log('Navigation complete');
  }

  const errorMessageStyle = {
    color: '#b94a48',
    backgroundColor: '#f2dede',
    border: '1px solid #eed3d7',
    padding: '10px',
    marginTop: '5px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center'
  };

  const errorIconStyle = {
    marginRight: '8px'
  };

  return (
    <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
      <form className="space-y-6" onSubmit={handleSubmit}>
        <h5 className="text-xl font-medium text-gray-900 dark:text-white">Sign Up</h5>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {error}
          </div>
        )}
        <div>
          <label htmlFor="email" className="block mb2 text-sm font-medium text-gray-900 dark:text-white">
            Your email
          </label>
          <input
            type="email"
            id="email"
            ref={emailRef}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="first.last@school.edu"
            required
          />
          {fieldErrors.email && (
            <div style={errorMessageStyle}>
              <span style={errorIconStyle}>⚠️</span>
              {fieldErrors.email}
            </div>
          )}
        </div>
        <div>
          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Your password
          </label>
          <input
            type="password"
            id="password"
            ref={passwordRef}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="••••••••"
            required
          />
          {fieldErrors.password && (
            <div style={errorMessageStyle}>
              <span style={errorIconStyle}>⚠️</span>
              {fieldErrors.password}
            </div>
          )}
        </div>
        <div>
          <label htmlFor="password-confirm" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Confirm password
          </label>
          <input
            type="password"
            id="password-confirm"
            ref={passwordConfirmRef}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="••••••••"
            required
          />
          {fieldErrors.passwordConfirm && (
            <div style={errorMessageStyle}>
              <span style={errorIconStyle}>⚠️</span>
              {fieldErrors.passwordConfirm}
            </div>
          )}
        </div>
        <div className="grid md:grid-cols-2 md:gap-6">
          <div>
            <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              First name
            </label>
            <input
              type="text"
              id="first-name"
              ref={firstNameRef}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
            />
            {fieldErrors.firstName && (
              <div style={errorMessageStyle}>
                <span style={errorIconStyle}>⚠️</span>
                {fieldErrors.firstName}
              </div>
            )}
          </div>
          <div>
            <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Last name
            </label>
            <input
              type="text"
              id="last-name"
              ref={lastNameRef}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
            />
            {fieldErrors.lastName && (
              <div style={errorMessageStyle}>
                <span style={errorIconStyle}>⚠️</span>
                {fieldErrors.lastName}
              </div>
            )}
          </div>
        </div>
        <button
          disabled={loading}
          type="submit"
          className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>
        <div className="text-sm font-medium text-gray-500 dark:text-gray-300">
          Already have an account?{' '}
          <Link to="/login" className="text-blue-700 hover:underline dark:text-blue-500">
            Log In
          </Link>
        </div>
      </form>
    </div>
  );
};

export default Signup;
