// GroupsList.js
import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { TextInput, Modal, Button, Badge } from 'flowbite-react';
import AssignUsersModal from './AssignUsersModal';
import { format } from 'date-fns';
import { FaEdit } from 'react-icons/fa';
import firebase from 'firebase/compat/app'; // Ensure compatibility

const GroupsList = ({ updateGroupCount }) => {
  const [staffGroups, setStaffGroups] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [faculties, setFaculties] = useState([]); // State to store faculty options
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [editGroup, setEditGroup] = useState(null); // For editing group details

  // Load faculties from local storage
  useEffect(() => {
    const storedFaculties = JSON.parse(localStorage.getItem('faculties'));
    if (storedFaculties && Array.isArray(storedFaculties)) {
      setFaculties(storedFaculties);
    } else {
      // Fallback list of faculties
      setFaculties([
        { Schools: 'Beedie School of Business' },
        { Schools: 'Faculty of Applied Sciences' },
        { Schools: 'Faculty of Arts and Social Sciences' },
        { Schools: 'Faculty of Communication, Art and Technology' },
        { Schools: 'Faculty of Education' },
        { Schools: 'Faculty of Environment' },
        { Schools: 'Faculty of Health Sciences' },
        { Schools: 'Faculty of Science' },
      ]);
    }
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const snapshot = await db.collection('groups').get();
        const groupsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStaffGroups(groupsData);
        setFilteredGroups(groupsData);
        updateGroupCount(snapshot.size);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, [updateGroupCount]);

  useEffect(() => {
    let filtered = [...staffGroups];
    if (searchTerm.trim() !== '') {
      const lowerSearch = searchTerm.toLowerCase();
      filtered = filtered.filter(group =>
        group.name.toLowerCase().includes(lowerSearch) ||
        group.description.toLowerCase().includes(lowerSearch) ||
        (group.purpose && group.purpose.toLowerCase().includes(lowerSearch))
      );
    }
    setFilteredGroups(filtered);
  }, [searchTerm, staffGroups]);

  const handleToggleCreateForm = () => setShowCreateForm(!showCreateForm);

  const handleCreateGroup = async (e) => {
    e.preventDefault();
    if (!groupName || !department) return alert('Group Name and Department are required!');

    try {
      const newGroup = {
        name: groupName,
        description,
        department,
        members: [],
        tags: [], // Initialize tags as empty array
        purpose: '', // Optional: Add purpose if needed
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      const docRef = await db.collection('groups').add(newGroup);
      const createdGroup = { id: docRef.id, ...newGroup };
      setStaffGroups([...staffGroups, createdGroup]);
      setFilteredGroups([...filteredGroups, createdGroup]);
      setGroupName('');
      setDescription('');
      setDepartment('');
      setShowCreateForm(false);
      updateGroupCount(filteredGroups.length + 1);
    } catch (error) {
      console.error('Error creating group:', error);
    }
  };

  const handleAssignUsers = (group) => {
    setSelectedGroup(group);
    setShowAssignModal(true);
  };

  const handleEditGroup = (group) => {
    setEditGroup(group);
  };

  const handleUpdateGroup = async (e) => {
    e.preventDefault();
    if (!editGroup.name || !editGroup.department) return alert('Group Name and Department are required!');

    try {
      await db.collection('groups').doc(editGroup.id).update({
        name: editGroup.name,
        description: editGroup.description,
        department: editGroup.department,
        tags: editGroup.tags || [],
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      });

      // Update local state
      const updatedGroups = staffGroups.map(group =>
        group.id === editGroup.id ? { ...editGroup, updatedAt: firebase.firestore.FieldValue.serverTimestamp() } : group
      );
      setStaffGroups(updatedGroups);
      setFilteredGroups(updatedGroups);
      setEditGroup(null);
    } catch (error) {
      console.error('Error updating group:', error);
    }
  };

  // Helper function to parse date
  const parseDate = (date) => {
    if (!date) return null;
    if (date.toDate) {
      // Firestore Timestamp
      return date.toDate();
    }
    if (date instanceof Date) {
      // JavaScript Date
      return date;
    }
    // Attempt to parse string
    const parsed = new Date(date);
    return isNaN(parsed) ? null : parsed;
  };

  // Helper function to validate date
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  return (
    <div>
      {/* Filter Card */}
      <div className="mb-4 bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-2 md:space-y-0">
          {/* Search Input */}
          <TextInput
            id="search"
            type="search"
            placeholder="Name, purpose, description..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full md:w-1/3"
          />
          <button
            onClick={handleToggleCreateForm}
            className="mt-2 md:mt-0 text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          >
            Create New Group
          </button>
        </div>
      </div>

      {/* Create Group Form Modal */}
      {showCreateForm && (
        <Modal show={showCreateForm} onClose={handleToggleCreateForm}>
          <Modal.Header>Create New Group</Modal.Header>
          <Modal.Body>
            <form onSubmit={handleCreateGroup}>
              <div className="mb-4">
                <label htmlFor="groupName" className="block mb-2 text-gray-700 dark:text-gray-300">Group Name:</label>
                <TextInput
                  type="text"
                  id="groupName"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="department" className="block mb-2 text-gray-700 dark:text-gray-300">Department/School:</label>
                <select
                  id="department"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600"
                >
                  <option value="">Select Department/School</option>
                  {faculties.map((faculty, index) => (
                    <option key={index} value={faculty.Schools}>
                      {faculty.Schools}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="description" className="block mb-2 text-gray-700 dark:text-gray-300">Description:</label>
                <TextInput
                  type="text"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter group description"
                />
              </div>
              <button
                type="submit"
                className="w-full text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
              >
                Create Group
              </button>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {/* Edit Group Modal */}
      {editGroup && (
        <Modal show={!!editGroup} onClose={() => setEditGroup(null)}>
          <Modal.Header>Edit Group</Modal.Header>
          <Modal.Body>
            <form onSubmit={handleUpdateGroup}>
              <div className="mb-4">
                <label htmlFor="editGroupName" className="block mb-2 text-gray-700 dark:text-gray-300">Group Name:</label>
                <TextInput
                  type="text"
                  id="editGroupName"
                  value={editGroup.name}
                  onChange={(e) => setEditGroup({ ...editGroup, name: e.target.value })}
                  required
                />
              </div>
              <div className="mb-4">
                <label htmlFor="editDepartment" className="block mb-2 text-gray-700 dark:text-gray-300">Department/School:</label>
                <select
                  id="editDepartment"
                  value={editGroup.department}
                  onChange={(e) => setEditGroup({ ...editGroup, department: e.target.value })}
                  required
                  className="w-full p-2 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600"
                >
                  <option value="">Select Department/School</option>
                  {faculties.map((faculty, index) => (
                    <option key={index} value={faculty.Schools}>
                      {faculty.Schools}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="editDescription" className="block mb-2 text-gray-700 dark:text-gray-300">Description:</label>
                <TextInput
                  type="text"
                  id="editDescription"
                  value={editGroup.description}
                  onChange={(e) => setEditGroup({ ...editGroup, description: e.target.value })}
                  placeholder="Enter group description"
                />
              </div>
              <button
                type="submit"
                className="w-full text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
              >
                Update Group
              </button>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {/* Assign Users Modal */}
      {selectedGroup && (
        <AssignUsersModal
          show={showAssignModal}
          onClose={() => setShowAssignModal(false)}
          groupId={selectedGroup.id}
          existingMembers={selectedGroup.members}
        />
      )}

      {/* Main List Card */}
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
        {filteredGroups.length === 0 ? (
          <p className="text-center text-gray-500">No groups found.</p>
        ) : (
          <div className="space-y-4">
            {filteredGroups.map((group) => {
              const updatedAtDate = parseDate(group.updatedAt);
              return (
                <div key={group.id} className="p-6 border rounded-md shadow-sm bg-gray-50 dark:bg-gray-800 flex flex-col md:flex-row justify-between items-start">
                  {/* Group Information */}
                  <div className="w-full md:w-3/4">
                    <div className="flex flex-col md:flex-row md:space-x-4">
                      {/* Left Column */}
                      <div className="flex-1">
                        <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">{group.name}</h3>
                        <p className="text-gray-700 dark:text-gray-300"><strong>Department:</strong> {group.department}</p>
                        <p className="text-gray-700 dark:text-gray-300"><strong>Description:</strong> {group.description || 'No description provided.'}</p>
                      </div>
                      {/* Right Column */}
                      <div className="flex-1 mt-4 md:mt-0">
                        <p className="text-gray-700 dark:text-gray-300"><strong>Members:</strong> {group.members.length}</p>
                        {/* Display Tags if available */}
                        {group.tags && group.tags.length > 0 && (
                          <div className="flex flex-wrap mt-2">
                            {group.tags.map((tag, index) => (
                              <Badge key={index} color="blue" className="mr-2 mb-2">
                                {tag}
                              </Badge>
                            ))}
                          </div>
                        )}
                        {/* Last Updated */}
                        <p className="text-sm text-gray-500 mt-2">
                          Last Updated: {isValidDate(updatedAtDate) ? format(updatedAtDate, 'dd MMM yyyy, h:mm a') : 'Never'}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Action Buttons */}
                  <div className="w-full md:w-1/4 flex flex-col md:items-end mt-4 md:mt-0 space-y-2">
                    <button
                      onClick={() => handleEditGroup(group)}
                      className="flex items-center justify-center text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                    >
                      <FaEdit className="mr-1" /> Edit
                    </button>
                    <button
                      onClick={() => handleAssignUsers(group)}
                      className="text-green-500 hover:text-white border border-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
                    >
                      Assign Users
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupsList;
