import React, { useState, useEffect, useCallback, useRef } from 'react';
import SemesterDetailsCourses from './SemesterDetailsPage';
import NavBar from '../NavBar';
import { db, auth } from '../firebase';
import { useParams, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'; // Import arrow icons

const SemesterDetails = () => {
  const { yearNumber, semester } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeTab = searchParams.get('plan');
  const [currentUser, setCurrentUser] = useState(null);
  const [finalizedTasks, setFinalizedTasks] = useState([]);
  const [cumulativeCourseIds, setCumulativeCourseIds] = useState([]);
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [numYears, setNumYears] = useState(yearNumber);
  const [isPanelVisible, setIsPanelVisible] = useState(true); // State to manage sidebar visibility
  const [isSidebarExtended, setIsSidebarExtended] = useState(false); // State to manage sidebar extension

  const sidebarRef = useRef(null); // Reference for the sidebar

  const generateInitialState = (numYears) => {
    const yearObject = {};
    for (let i = 1; i <= numYears; i++) {
      yearObject[`year${i}`] = {
        Fall: [],
        Spring: []
      };
    }
    return yearObject;
  };

  const [finalizedOrganizedCourses, setFinalizedOrganizedCourses] = useState(generateInitialState(numYears));
  const [userRecsData, setUserRecsData] = useState({});
  const [transformedMajorRecData, setTransformedMajorRecData] = useState([]);

  const { fetchedCourseData, fetchedPrereqData, fetchedElective, pathwayData, tasks, fetchedMajorRecs } = useAuth();

  useEffect(() => {
    if(fetchedMajorRecs){
      const transformedMajorRecData = fetchedMajorRecs.reduce((acc, curr) => {
        const { Major, ...categories } = curr;
        acc[Major] = categories;
        return acc;
      }, {});  
      setTransformedMajorRecData(transformedMajorRecData);
    }
  }, [fetchedMajorRecs]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = auth.currentUser;
        setCurrentUser(user);

        if (user) {
          const userDocRef = db.collection('users').doc(user.uid);
          const userDoc = await userDocRef.get();

          if (userDoc.exists) {
            const userData = userDoc.data();
            const courseIds = userData.schedules[activeTab] || [];
            const prevCourses = [];
            const taskIds = userData.finalizedTasks || [];

            setNumYears(userData?.schedules[activeTab].numYears);

            const backendSurveyAnswers = userData.surveyAnswers || [];
            setSurveyAnswers(backendSurveyAnswers);
            const allCourses = fetchedCourseData;
            const prereqs = fetchedPrereqData;
            const tasks1 = tasks;

            const backendUserRecData = userData.recommendationRatings || {};
            setUserRecsData(backendUserRecData)

            setFinalizedTasks(tasks1.filter((task) => taskIds.includes(task.Task_ID)));

            let cumulativeIds = {};
            cumulativeIds['year0'] = prevCourses;
            let prevYearsCourses = prevCourses;
            for (let i = 1; i <= 4; i++) {
              let year = `year${i}`;
              let yearCourses = {};
              let cumulativeCourses = prevYearsCourses;
              for (let j = 1; j <= 2; j++) {
                let semester = `S${j}`;
                if (courseIds && courseIds[year] && courseIds[year][semester]) {
                  cumulativeCourses = cumulativeCourses.concat(courseIds[year][semester].courses);
                }
                cumulativeCourses = Array.from(new Set(cumulativeCourses));
                yearCourses[semester] = [...cumulativeCourses];
              }
              prevYearsCourses = prevYearsCourses.concat(cumulativeCourses);
              prevYearsCourses = Array.from(new Set(prevYearsCourses));
              cumulativeIds[year] = yearCourses;
            }
            setCumulativeCourseIds(cumulativeIds);

            const coursesByYearAndSemester = {};
            for (const yearKey in courseIds) {
              const yearObj = courseIds[yearKey];
              for (const semesterKey in yearObj) {
                const semesterIds = yearObj[semesterKey].courses;
                const semesterClasses = allCourses.filter((course) =>
                  semesterIds.includes(course.courseInfo_courseNumber)
                );
                for (const value in semesterClasses) {
                  const courses = prereqs.map((course) => course.Course);
                  if (courses.includes(semesterClasses[value].courseInfo_courseNumber)) {
                    const course = prereqs.find(
                      (course) => course.Course === semesterClasses[value].courseInfo_courseNumber
                    );
                    let arrayOfPrerequisites = course.Prerequisites.split('&');
                    arrayOfPrerequisites = arrayOfPrerequisites.map((element) => element.replace(/\(|\)/g, ''));
                    let finalPrerequisiteArray = arrayOfPrerequisites.map((element) => element.split('||'));
                    semesterClasses[value].Prerequisites = finalPrerequisiteArray;
                    let arrayOfCorequisites = course.Corequisites.split('&');
                    arrayOfCorequisites = arrayOfCorequisites.map((element) => element.replace(/\(|\)/g, ''));
                    let finalCorequisiteArray = arrayOfCorequisites.map((element) => element.split('||'));
                    semesterClasses[value].Corequisites = finalCorequisiteArray;
                  }
                  if (
                    semesterClasses[value].Prerequisites === '' ||
                    semesterClasses[value].Prerequisites[0] === '' ||
                    semesterClasses[value].Prerequisites[0][0] === ''
                  ) {
                    continue;
                  } else {
                    for (let iterator in semesterClasses[value].Prerequisites) {
                      semesterClasses[value].isPrereqMet = false;
                      for (let iterator2 in semesterClasses[value].Prerequisites[iterator]) {
                        if (yearKey === 'year1' && semesterKey === 'S1') {
                          if (
                            cumulativeIds['year0'].includes(
                              semesterClasses[value].Prerequisites[iterator][iterator2]
                            )
                          ) {
                            semesterClasses[value].isPrereqMet = true;
                            break;
                          }
                        } else if (semesterKey === 'S1') {
                          const lastyear = parseInt(yearKey.charAt(4)) - 1;
                          if (
                            cumulativeIds[`year${lastyear}`]['S2'].includes(
                              semesterClasses[value].Prerequisites[iterator][iterator2]
                            )
                          ) {
                            semesterClasses[value].isPrereqMet = true;
                            break;
                          }
                        } else {
                          if (
                            cumulativeIds[yearKey]['S1'].includes(
                              semesterClasses[value].Prerequisites[iterator][iterator2]
                            )
                          ) {
                            semesterClasses[value].isPrereqMet = true;
                            break;
                          }
                        }
                      }
                      if (semesterClasses[value].isPrereqMet === false) {
                        break;
                      }
                    }
                  }
                  if (
                    semesterClasses[value].Corequisites === '' ||
                    semesterClasses[value].Corequisites[0] === '' ||
                    semesterClasses[value].Corequisites[0][0] === ''
                  ) {
                    continue;
                  } else {
                    for (let iterator in semesterClasses[value].Corequisites) {
                      semesterClasses[value].isPrereqMet = false;
                      for (let iterator2 in semesterClasses[value].Corequisites[iterator]) {
                        if (
                          cumulativeIds[yearKey][semesterKey].includes(
                            semesterClasses[value].Corequisites[iterator][iterator2]
                          )
                        ) {
                          semesterClasses[value].isPrereqMet = true;
                          break;
                        }
                      }
                    }
                  }
                }
                const semesterName = semesterKey === 'S1' ? 'Fall' : semesterKey === 'S2' ? 'Spring' : 'Uncategorized';
                if (!coursesByYearAndSemester[yearKey]) {
                  coursesByYearAndSemester[yearKey] = { Fall: [], Spring: [] };
                }
                if (semesterName !== 'Uncategorized') {
                  coursesByYearAndSemester[yearKey][semesterName].push(...semesterClasses);
                }
              }
            }
            setFinalizedOrganizedCourses(coursesByYearAndSemester);
          }
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const handleRemove = useCallback(
    async (classToRemove, year, sem) => {
      const removedCourseNumber = classToRemove.courseInfo_courseNumber;
      try {
        if (currentUser && currentUser.uid) {
          const userRef = db.collection('users').doc(currentUser.uid);
          let semesterCode = sem === 'Fall' ? 'S1' : 'S2';

          await db.runTransaction(async (transaction) => {
            const userDoc = await transaction.get(userRef);
            if (!userDoc.exists) {
              throw new Error('User data not found');
            }

            const userData = userDoc.data();
            const previousFinalizedSchedule = userData.finalizedSchedule || {};
            const updateObj = previousFinalizedSchedule ? { ...previousFinalizedSchedule } : {};
            const newUpdateObj = {
              ...updateObj,
              [`year${year}`]: {
                ...updateObj[`year${year}`],
                [semesterCode]: {
                  ...updateObj[`year${year}`][semesterCode],
                  courses: updateObj[`year${year}`][semesterCode].courses.filter(
                    (item) => item !== removedCourseNumber
                  ),
                },
              },
            };

            transaction.update(userRef, {
              finalizedSchedule: newUpdateObj,
            });
          }).catch((error) => {
            console.error('Transaction failed:', error);
            throw error;
          });

          setFinalizedOrganizedCourses((prev) => {
            const newCourses = { ...prev };
            newCourses[`year${year}`][sem] = newCourses[`year${year}`][sem].filter(
              (course) => course.courseInfo_courseNumber !== removedCourseNumber
            );
            return newCourses;
          });
        } else {
          console.error('Error: currentUser or currentUser.uid is not defined');
        }
      } catch (error) {
        console.error('Error handling removal:', error);
      }
    },
    [currentUser]
  );

  let semesterName = semester === 'S1' ? 'Fall' : 'Spring';

  const calculateRecScore = require('../CourseRecommendation');

  const filteredCourses = finalizedOrganizedCourses[`year${yearNumber}`][semesterName].map((course) => {
    return {
      ...course,
      recScore: calculateRecScore(transformedMajorRecData[course.Major], userRecsData, course, surveyAnswers),
    };
  });

  const filterTasksByYearAndSemester = (tasks, year, semester) => {
    const filteredTasks = tasks.filter((task) => {
      return task.Task_Year === Number(year) && task.Task_Sem === semester;
    });

    return filteredTasks;
  };

  const filteredTasks = filterTasksByYearAndSemester(finalizedTasks, yearNumber, semester);

  const getTotalCredits = () => {
    return filteredCourses.reduce((total, course) => total + course.Credits, 0);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsPanelVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='w-full flex'>
      
      {/* Sidebar for Courses */}
      <div
        ref={sidebarRef}
        className={`fixed inset-y-0 left-0 z-20 w-1/4 bg-white border-r border-gray-300 transition-transform duration-300 ${
          isPanelVisible ? 'translate-x-0' : '-translate-x-full'
        } overflow-y-auto`}
        style={{ boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)' }}
      >
        {/* Courses List */}
        <div className="p-4">
        <h4 className="text-lg font-medium text-gray-700">Semester Details: <span className="text-lg font-light text-gray-600">Year {yearNumber}, {semesterName} Semester</span></h4>
          <SemesterDetailsCourses
            finalizedCourses={filteredCourses}
            onRemove={(classToRemove) => handleRemove(classToRemove, yearNumber, semesterName)}
          />
        </div>
        
        {/* Timeline Extension */}
        {isSidebarExtended && (
          <div className="p-4 border-t border-gray-300">
            <h4 className="text-md font-bold mb-2">Select Year and Semester</h4>
            <div className="flex flex-col items-start">
              {Array.from({ length: numYears }).map((_, index) => (
                <div key={index} className="flex flex-col mb-2">
                  <span className="font-bold">Year {index + 1}</span>
                  <div className="flex space-x-2 mt-1">
                    <Link to={`/SemesterDetails/${index + 1}/S1`} className="text-blue-500 hover:underline">Fall</Link>
                    <Link to={`/SemesterDetails/${index + 1}/S2`} className="text-blue-500 hover:underline">Spring</Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
       
        {/* Toggle Button for Extending Sidebar */}
        <button
          className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-200"
          onClick={() => setIsSidebarExtended(!isSidebarExtended)}
        >
          <BsThreeDotsVertical size={24} />
        </button>
      </div>
 
      {/* Main Content */}
      <div className="flex-1 p-4 bg-gray-50 border border-gray-300 rounded shadow-md relative">
        <h2 className="text-center text-lg font-bold mt-0">
          Year {yearNumber} - {semesterName} Semester
        </h2>
        <span className="text-gray-600 font-bold">Total Credits: {getTotalCredits()}</span>
        <div className="mt-4" style={{ textAlign: 'center' }}>
          <Link
            to={{
              pathname: '/Search',
              search: `?yearNumber=${yearNumber}&semester=${semesterName}`,
            }}
            className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Add Course
          </Link>
        </div>

        {/* Arrow to Extend/Collapse Sidebar */}
        <button
          className="fixed top-1/2 right-0 p-2 bg-white rounded-l-lg border border-gray-300 shadow-md transform -translate-y-1/2 hover:bg-gray-200"
          onClick={() => setIsPanelVisible(!isPanelVisible)}
        >
          {isPanelVisible ? <FaChevronLeft size={18} /> : <FaChevronRight size={18} />}
        </button>
      </div>
    

    </div>
    
  );
};

export default SemesterDetails;
