import React, { useState, useEffect, useRef } from 'react';
import { TextInput } from 'flowbite-react';
import { toast } from 'react-toastify'; // Ensure react-toastify is installed

const MultiSelect = ({ suggestions, value = [], onChange, placeholder, required, unselectable = [], tagColor }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [warning, setWarning] = useState(false);
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
        if (required && value.length === 0) {
          setWarning(true);
        } else {
          setWarning(false);
        }
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [value, required]);

  const handleChange = (e) => {
    const userInput = e.target.value;
    setInputValue(userInput);

    const filtered = suggestions
      .filter(suggestion => typeof suggestion === 'string')
      .filter(suggestion => suggestion.toLowerCase().includes(userInput.toLowerCase()));

    setFilteredSuggestions(filtered);
    setShowSuggestions(true);
    setWarning(false); // Reset warning when user starts typing again
  };

  const handleCheckboxChange = (suggestion) => {
    const isSelected = value.includes(suggestion);
    let updatedItems;

    if (isSelected) {
      updatedItems = value.filter(item => item !== suggestion);
    } else {
      updatedItems = [...value, suggestion];
    }

    onChange({
      target: { value: updatedItems }
    });

    setInputValue(''); // Clear input
  };

  const handleTagRemove = (item) => {
    onChange({ target: { value: value.filter(selectedItem => selectedItem !== item) } });
  };

  const handleInputFocus = () => {
    setFilteredSuggestions(suggestions); // Show all suggestions on focus
    setShowSuggestions(true);
  };

  return (
    <div className="relative" ref={autocompleteRef}>
      <div>
        <input ref={inputRef} type="text" placeholder={placeholder} value={inputValue} onChange={handleChange} onFocus={handleInputFocus} className="w-full bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
      </div>
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="absolute bg-white border border-gray-300 rounded w-full mt-1 max-h-40 overflow-y-auto z-10">
          {filteredSuggestions.map((suggestion, index) => (
            <li key={index}>
              <div
                className="flex items-center p-2 rounded bg-blue hover:bg-gray-100 cursor-pointer"
                onClick={() => handleCheckboxChange(suggestion)}
              >
                <input
                  type="checkbox"
                  checked={value.includes(suggestion)}
                  onChange={() => handleCheckboxChange(suggestion)}
                  className="w-4 h-4 text-indigo-600 bg-gray-100 border-gray-300 rounded focus:ring-indigo-500"
                  onClick={(e) => e.stopPropagation()} // Prevent the checkbox click from bubbling up
                />
                <label className="w-full ms-2 text-sm font-medium text-gray-900">{suggestion}</label>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="mt-2 flex flex-wrap gap-1">
        {value.map((item, index) => (
          <span
            key={index}
            id={`badge-dismiss-${item}-${index}`}
            className={`inline-flex items-center px-2 py-1 text-sm font-medium text-${tagColor}-800 bg-${tagColor}-100 rounded dark:bg-${tagColor}-900 dark:text-${tagColor}-300`}
          >
            {item}
            <button
              type="button"
              className={`inline-flex items-center p-1 ms-2 text-sm text-${tagColor}-400 bg-transparent rounded-sm hover:bg-${tagColor}-200 hover:text-${tagColor}-900 dark:hover:bg-${tagColor}-800 dark:hover:text-${tagColor}-300`}
              onClick={() => handleTagRemove(item)}
              aria-label={`Remove ${item}`}
            >
              <svg
                className="w-2 h-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Remove badge</span>
            </button>
          </span>
        ))}
      </div>
      {warning && required && (
        <div className="text-red-500 mt-2">Please select at least one option.</div>
      )}
    </div>
  );
};

export default MultiSelect;
