import React from 'react';

const PathwayClassCart = ({ classesInCart, onRemoveClass, onAddToPathway }) => {
  const handleAddToPathway = () => {
    if (classesInCart.length === 0) {
      alert('No classes in the cart to add.');
      return;
    }
    onAddToPathway(); // This will handle adding classes and closing modal
  };

  return (
    <div className="class-cart p-3 bg-gray-100 w-full min-h-200 mb-20 rounded-lg">
      <h5 className="text-lg text-center font-bold mb-4">Class Cart</h5>
      {classesInCart.length === 0 ? (
        <p className="text-md text-center">Add classes to the cart</p>
      ) : (
        <div>
          {classesInCart.map((classData) => (
            <ClassCartItem
              key={classData.courseInfo_courseNumber} // Use unique identifier
              classData={classData}
              onRemoveClass={onRemoveClass}
            />
          ))}
          <div className="flex justify-center mt-4">
            <button
              className="w-full bg-green-500 hover:bg-green-700 text-white text-sm py-2 px-4 rounded"
              onClick={handleAddToPathway}
            >
              Add To Pathway
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

const ClassCartItem = ({ classData, onRemoveClass }) => {
  return (
    <div className="class-cart-item bg-white mb-2 rounded p-2 relative">
      <p className="font-bold">{classData.courseInfo_courseNumber}</p>
      <div className="absolute bottom-2 right-3">
        <button
          className="text-xs text-red-500 hover:text-black"
          onClick={() => onRemoveClass(classData)}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

export default PathwayClassCart;