// MilestonesSection.jsx
import React from 'react';

const MilestonesSection = ({ milestones, setMilestones, setShowClassSearch }) => {
  const handleRemoveMilestone = (index) => {
    const updatedMilestones = milestones.filter((_, idx) => idx !== index);
    setMilestones(updatedMilestones);
  };

  const handleAddMilestone = () => {
    setMilestones([
      ...milestones,
      {
        title: '', // Ensure title is initialized
        Description: '', // Ensure Description is initialized
        courseInfo_courseNumber: '',
        courseYear: '',
        CourseSem: '',
        courseType: 'Milestone',
      },
    ]);
  };

  const handleChange = (index, field, value) => {
    const updatedMilestones = [...milestones];
    updatedMilestones[index][field] = value || ''; // Ensure value is never undefined
    setMilestones(updatedMilestones);
  };

  return (
    <div className="mb-4">
      {/* Optional: Section Title */}
      <h2 className="text-2xl font-semibold mb-4">Milestones</h2>

      {milestones.length === 0 ? (
        <p className="text-gray-700">No milestones currently included</p>
      ) : (
        <ul className="space-y-4">
          {milestones.map((milestone, index) => (
            <li key={index} className="p-4 bg-gray-50 rounded-lg shadow-md">
              {/* Milestone Header with Remove Button */}
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-medium">Milestone {index + 1}</h3>
                <button
                  type="button"
                  onClick={() => handleRemoveMilestone(index)}
                  className="bg-red-600 text-white px-3 py-1 rounded-lg hover:bg-red-700 transition-colors"
                >
                  Remove
                </button>
              </div>

              {/* Title Input */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  value={milestone.title || ''}
                  onChange={(e) => handleChange(index, 'title', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Milestone Title"
                />
              </div>

              {/* Description Input */}
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  value={milestone.Description || ''}
                  onChange={(e) => handleChange(index, 'Description', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter Milestone Description"
                  rows={3}
                />
              </div>

              {/* Course Year and Semester Select */}
              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label className="block text-sm font-medium text-gray-700">Course Year</label>
                  <select
                    value={milestone.courseYear || ''}
                    onChange={(e) => handleChange(index, 'courseYear', e.target.value)}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="" disabled>
                      Select Year
                    </option>
                    {[1, 2, 3, 4].map((year) => (
                      <option key={year} value={year}>
                        Year {year}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/2">
                  <label className="block text-sm font-medium text-gray-700">Semester</label>
                  <select
                    value={milestone.CourseSem || ''}
                    onChange={(e) => handleChange(index, 'CourseSem', e.target.value)}
                    className="block w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="" disabled>
                      Select Semester
                    </option>
                    <option value="S1">Semester 1</option>
                    <option value="S2">Semester 2</option>
                  </select>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
      <button
        type="button"
        onClick={handleAddMilestone}
        className="w-full mt-4 bg-blue-600 text-white px-3 py-2 rounded-lg hover:bg-blue-700 transition-colors"
      >
        Add Milestone
      </button>
    </div>
  );
};

export default MilestonesSection;
